import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const Bb = styled.b`
  font-size: 1.1em;
  font-weight: 900;
`

const CrashButton = styled.div`
  margin-left: .5rem;
  height: 1.2rem;
  width: 7rem;
  border: 1px red solid;
  color: red;
  border-radius: .5rem;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const ProductionVersion = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: .3rem;
  font-weight: bold;
  & > div:not(:first-child) {
    margin-left: 1rem;
  }
`;

const DevelopmentVersion = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ProdVersionItem = styled.div`
  font-size: ${ fontSizes.xxs };
  color: ${ colors.gray.s500 };
`;

import jsbridge from 'utils/jsbridge'
import {fontSizes} from "../../utils/fonts";
import {colors} from "../../utils/colors";

function Version({ ...rest }) {

  const [isVisible, setVisible] = useState(false)
  const [version, setVersion] = useState({ env: null, webApp: null, tvApp: null})

  useEffect(() => {
    const notProd = process.env.REACT_APP_API !== 'https://backend.sparko.tv/'
    setVisible(notProd)

    const appInfo = jsbridge.call('getAppInfo')
    const info = JSON.parse(appInfo)

    const currentVersion = {
      env: info.env,
      isLocalApp: info.isLocalApp,
      webApp: process.env.APP_VERSION,
      tvApp: info.tvVersion,
      network: info.network,
      ipAddress: info.ipAddress
    }

    setVersion(currentVersion)

  }, [isVisible])

  const throwTestError = () => {
    throw new Error('TEST ERROR');
  }

  return (
      <div {...rest}>
        <ProductionVersion>
          <ProdVersionItem>web: {version.webApp || '-'}</ProdVersionItem>
          <ProdVersionItem>tv: {version.tvApp || '-'}</ProdVersionItem>
        </ProductionVersion>
        {isVisible &&
        <DevelopmentVersion>
          <Bb> env:</Bb>{version.env || '-'}
          {/*<Bb> web:</Bb>{version.webApp || '-'}*/}
          {/*<Bb> tv:</Bb>{version.tvApp || '-'}*/}
          <Bb> net:</Bb>{version.network || '-'}
          <Bb> ip:</Bb>{version.ipAddress || '-'}
          {/*<CrashButton onClick={ throwTestError }>*/}
          {/*  Crash test*/}
          {/*</CrashButton>*/}
        </DevelopmentVersion>
        }
      </div>
  )
}

export default Version

export const getBoundingClientRect = (element) => {
  const rect = element.getBoundingClientRect()
  const { top, right, bottom, left, width, height } = rect
  return { top, right, bottom, left, width, height, centerX: left + (width / 2), centerY: (top + bottom) / 2 }
}

export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

function debounce(fn, delay) {
  const ms = parseInt(delay, 10)
  let timer
  return function (...args) {
    let isFast
    args.forEach(arg => { if (arg.fast) isFast = true })
    clearTimeout(timer)
    if (isFast) {
      fn(...args)
    } else {
      timer = setTimeout(() => fn(...args), isNaN(ms) ? 1000 : ms)
    }
  }
}

function throttle(fn, delay) {
  const ms = parseInt(delay, 10)
  let timer = null
  return function (...args) {
    if (!timer) {
      fn(...args)
      timer = setTimeout(() => timer = null, isNaN(ms) ? 1000 : ms)
    }
  }
}

function decorate(fn) {
  function calledWithParam(delay) {
    return (target, property, descriptor) => calledWithoutParam(target, property, descriptor, delay)
  }

  function calledWithoutParam(target, property, descriptor, delay) {
    const method = descriptor.initializer
    descriptor.initializer = function initializer() {
      return fn(method.call(this), delay)
    }
  }

  return function (...arg) {
    if (typeof arg[0] === 'function') return fn(...arg)
    if (typeof arg[0] === 'number' || typeof arg[0] === 'string') return calledWithParam(...arg)
    if (typeof arg[0] === 'object') return calledWithoutParam(...arg)
  }
}

function log(fn) {
  console.log('--- log -> log -> ', fn.name)
}

export const logged = decorate(log)
export const debounced = decorate(debounce)
export const throttled = decorate(throttle)

export function isInBrowser() {
  const agent = navigator.userAgent
  return /Macintosh/.test(agent)
}

function drawTriangle(x1, y1, x2, y2, x3, y3) {
  const canvas = document.getElementById('canvasTriangle')
  canvas.style.zIndex = '999'
  canvas.width = window.innerWidth
  canvas.height = window.innerHeight
  
  if (canvas.getContext) {
    let ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    ctx.fillStyle = 'rgba(0,0,0,0.2)'
    
    ctx.beginPath()
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.lineTo(x3, y3)
    ctx.fill()
  }
}

export function drawItemCenter(x, y) { //TODO This dont work yet
  const canvas = document.getElementById('canvasCenter')
  canvas.style.zIndex = '1000'
  canvas.width = window.innerWidth
  canvas.height = window.innerHeight


  if (canvas.getContext) {
    let ctx = canvas.getContext('2d')
    ctx.fillStyle = 'rgba(255,0,0,0.6)'

    ctx.beginPath()
    ctx.arc(x, y, 5, 0, Math.PI * 2, true)
    ctx.fill()
  }
}

const DEBUG = true
const EXPAND_TRIANGLE = 500 // 500
export const isInTriangle = (params = {}, options = {}) => {
  const { item, from, where, offset = 0 } = params
  const { debug = DEBUG, expandTriangle = EXPAND_TRIANGLE } = options
  
  const area = (x1, y1, x2, y2, x3, y3) => {
    return Math.abs((x1 * (y2 - y3) + x2 * (y3 - y1) + x3 * (y1 - y2)) / 2 )
  }
  const isInside = (x1, y1, x2, y2, x3, y3, x, y) => {
    const A = area (x1, y1, x2, y2, x3, y3)
    const A1 = area (x, y, x2, y2, x3, y3)
    const A2 = area (x1, y1, x, y, x3, y3)
    const A3 = area (x1, y1, x2, y2, x, y)

    return Math.floor(A) === Math.floor(A1 + A2 + A3)
  }
  const trianglePoints = (from, where) => {
    if (where === 'up') {
      const x1 = from.centerX
      const y1 = from.centerY + offset

      const y2 = 0
      const y3 = 0

      const x2 = x1 - y1 - expandTriangle
      const x3 = x1 + y1 + expandTriangle

      if (debug) drawTriangle(x1, y1, x2, y2, x3, y3)
      return [x1, y1, x2, y2, x3, y3]
    }
    if (where === 'right') {
      const x1 = from.right
      const y1 = from.centerY

      const x2 = window.innerWidth
      const x3 = window.innerWidth

      const y2 = y1 - (x2 - x1) - expandTriangle
      const y3 = y1 + (x3 - x1) + expandTriangle

      if (debug) drawTriangle(x1, y1, x2, y2, x3, y3)
      return [x1, y1, x2, y2, x3, y3]
    }
    if (where === 'down') {
      const x1 = from.centerX
      const y1 = from.centerY

      const y2 = Math.max(document.documentElement.offsetHeight, 2000)
      const y3 = Math.max(document.documentElement.offsetHeight, 2000)

      const x2 = x1 - (y2 - y1) - expandTriangle
      const x3 = x1 + (y3 - y1) + expandTriangle

      if (debug) drawTriangle(x1, y1, x2, y2, x3, y3)
      return [x1, y1, x2, y2, x3, y3]
    }
    if (where === 'left') {
      const x1 = from.left
      const y1 = from.centerY

      const x2 = 0
      const x3 = 0

      const y2 = y1 - x1 - expandTriangle
      const y3 = y1 + x1 + expandTriangle

      if (debug) drawTriangle(x1, y1, x2, y2, x3, y3)
      return [x1, y1, x2, y2, x3, y3]
    }
  }

  // if (debug) drawItemCenter(item.centerX, item.centerY)
  const points = [...trianglePoints(from, where), item.centerX, item.centerY]
  return isInside(...points)
}

export const getNodeTranslateY = node => {
  const string = node.style.transform
  if (!string) return 0
  const regex = /translateY\((calc\()?(.*)px\)+/
  return string.replace(regex, '$2') * 1
}

import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";

import WelcomeScreen from "./components/Welcome";
import userStore from "./stores/userStore";
import SwitchUser from "./components/SwitchUser/SwitchUser";
import Layout from "./components/Layout";

import jsbridge from "utils/jsbridge";

import pageStore from "stores/pageStore";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuth = userStore.isAuth;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/switchUser", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export const router = {
  history: null,
  goBack() {
    const path = this.map;
    if (typeof path === "string") return this.history.push(path);
    if (typeof path === "function") return path();
    document.withNavigation
      .nextWith("name")
      .equals(router.history.location.state.prev);
    return this.history.goBack();
  },
  get map() {
    const mainId = pageStore.mainId;
    const toMain = () => `/main/${mainId}`;
    const maps = {
      "/welcome": () => jsbridge.call("switchToHdmi"),
      [`/main/${mainId}`]: "/welcome",
      "/main/messages": toMain(),
      "/main/diary": toMain(),
      "/main/contacts": toMain(),
      "/main/interests": toMain(),
      "/main/services": toMain(),
    };
    return maps[window.location.pathname];
  },
  navigateTo(ofPage, method = "push") {
    const getLink = (page) => {
      const pages = {
        main: "/main",
        messages: "/main/messages",
        photos: "/main/photos",
        diary: "/main/diary",
        calendar: "/main/diary",
        contacts: "/main/contacts",
        butler: "/main/services",
        welcome: "/welcome",
      };
      return pages[page] || pages["main"];
    };
    if (router.history) {
      jsbridge.call("openPage", ofPage, process.env.APP_VERSION);
      router.history.push(getLink(ofPage));
    }
  },
};

const Routes = ({ history, pageStore, location }) => {
  useEffect(() => {
    const push = new Proxy(history.push, {
      apply(target, thisArg, argArray) {
        target.apply(thisArg, [
          ...argArray,
          { prev: document.withNavigation.getSelectedFocusable()?.name },
        ]);
      },
    });

    history.push = push;
    router.history = history;
    history.router = router;
  }, []);
  return (
    <Switch>
      <Redirect exact from="/" to="/welcome" /> {/* index path */}
      <Redirect exact from="/main" to={`/main/${pageStore.mainId}`} />{" "}
      {/* Redirection for other screens */}
      <Redirect from="/([a-z]+)//([a-z]+)/" to="/main/:1" />{" "}
      {/* Redirection in case of double slash */}
      <Route path="/switchUser" component={SwitchUser} />
      <PrivateRoute path="/welcome" component={WelcomeScreen} />
      <PrivateRoute path="/main" component={Layout} />
    </Switch>
  );
};

export default inject("pageStore")(observer(withRouter(Routes)));

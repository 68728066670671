import { observable, computed, action, toJS, reaction } from "mobx"
import { fromPromise } from "mobx-utils"

import { withStatusFor } from "utils/storeHelpers"
import { api } from "utils/fetcher"
import jsbridge from 'utils/jsbridge'

class ServicesStore {

  constructor() {
    reaction(
      () => !!this.link.length,
      () => { this._storedItems = this.link }
    )
  }

  _storedItems = []
  @observable _items = []
  @computed get link() { return this._items?.value || [] }

  @observable _handled = null
  @computed get handled() { return this._handled?.value || [] }
  @action getHandled = () => (this._handled = fromPromise(api.get().from.services.handled()))

  @action getServices = () => {
    this._items = fromPromise(api.get().from.services.all())
  }

  @computed get services() {
    this._storedItems = this.link
      .filter(item => item.id !== 26)
      .map(item => ({
        ...item,
        href: `services/${item.id}`,
        description: item.description.replace(/(<[\/]?br>)|(<[^>]*>)/g, '\n\r', ' ')
      }))

    return this._storedItems
  }

  getCurrent = serviceId => this.services.find(service => service.id === parseInt(serviceId)) || {}

  @action
  sendServiceRequest = async serviceId => {
    const current = this.getCurrent(serviceId)
    if (!current.id) return false

    const body = {
      butler: current.id,
      message: current.title
    }
    try {
      const res = await api.post(body).to.services.requestService()
      if(res && res.response){
        jsbridge.call('toast', res.response)
      }
      this.getHandled()
    } catch (e) {
      console.log("err", e)
    }
  }

  isRequested = title => !!this.handled.find(item => item?.message === title)

  howManyRequested = title => this.handled.filter(item => item?.message === title).length

  refreshStore = () => {
    this.getHandled()
    this.getServices()
  }
}

const servicesStore = new ServicesStore()
export default withStatusFor(servicesStore, { withMainField: '_items', onPath: '\/main\/services' })

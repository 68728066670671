const config = {
  dateTimeFormat: 'dd MMM HH:mm',
  timeFormat: 'HH:mm',
  timeAMPM: 'hh:mm a',
  dateFormat: 'dd MMM yyyy',
  shortDateFormat: 'dd MMM',
  longDateFormat :'dd MMM yyyy HH:mm a',
  getDayofWeek :'EEEE'
}
export default config

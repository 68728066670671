import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Block, Icon, Img, Text, theme } from 'UI'

const pulse = keyframes`
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1.0);
  }
`
const animation = css`
  animation: ${pulse} 1.2s ease-in-out infinite;
`

const Thumb = styled(Img)`
  width: 14vw;
  border-radius: ${theme.borderRadius};
`

const Texts = styled.div`
  padding: 0 ${theme.interval()};
  flex: 1;
`

const Header = styled(Text.H2)`
  word-break: break-word;
`

const IconWrap = styled(Block)`
  flex: 0 0 5vw;
  height: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.interval()};
  ${p => p.selected && animation}
`

const PulseIcon = styled(Icon)`
  font-size: 4vw;
`

function YoutubeListItem({ selected, content_header, content_markup, content_youtube_id, content_thumbnail,  component, ...rest }) {
  return (
    <>
      <Thumb src={content_thumbnail ? content_thumbnail : `https://i.ytimg.com/vi/${content_youtube_id}/mqdefault.jpg`} key={`thumb_${content_youtube_id}`} />
      <Texts key={`texts${content_youtube_id}`}>
        <Header>{content_header}</Header>
      </Texts>
      <IconWrap selected={selected} key={`icon_${content_youtube_id}`}><PulseIcon icon='video' /></IconWrap>
    </>
  )
}

export default YoutubeListItem

import React, { createRef, forwardRef, Component } from 'react'
import { findDOMNode } from 'react-dom'
import uuid from 'uuid/v4'

import { getDisplayName, isInBrowser } from './utils'
import area from './area'

function focusable(WrappedComponent, passedOptions = {}) {

  const options = {
    first: false,
    focusOrder: 100,
    disabled: false,
    name: getDisplayName(WrappedComponent),
    isList: false,
    isSub: false,
    ...passedOptions
  }

  const WithRef = Component => forwardRef((props, ref) => <Component {...props} forwardedRef={ref} />)
  const Wrapped = WithRef(WrappedComponent)

  class Focusable extends Component {
    static contextType = area.context
    
    state = {
      selected: false,
    }

    itemEl = createRef()
    _focus_id = uuid()
    callback = null
  
    componentDidMount() {
      setTimeout(() => this.makeThisFocusable(), 0)
      // if (this.itemEl?.current) this.itemEl.current.onclick = this.handleClick
    }

    componentDidUpdate() {
      const { disabled } = this.props
      const isDisabled = disabled === 'yes' || disabled === true
      setTimeout(() => {
        const isInItems = document.withNavigation ? document.withNavigation.checkFocusable(this._focus_id) !== -1 : false;
        if (!isInItems && !isDisabled) this.makeThisFocusable()
        if (isInItems && isDisabled) this.makeThisDeleted()
      }, 0)
    }

    makeThisFocusable = () => {
      const {
        onPress = this.handlePress,
        first = options.first,
        disabled = options.disabled,
        isList = options.isList,
        isSub = options.isSub,
        focusOrder = options.focusOrder,
        name = options.name,
        _area_id = this.context?.areaId || false,
        onlyArea = this.context?.onlyArea || false,
        oneDimension = this.context?.oneDimension || false,
        log
      } = this.props

      const isDisabled = disabled === 'yes' || disabled === true
      const node = findDOMNode(this.itemEl.current)

      if (isDisabled || !node) return false
      try {
        document.withNavigation.addFocusable({
          _focus_id:      this._focus_id,
          _area_id:       _area_id,
          name:           name,
          ref:            node,
          onlyArea:       onlyArea,
          first:          first,
          isList:         isList,
          isSub:          isSub,
          focusOrder:     focusOrder,
          oneDimension:   oneDimension,
          onPress:        onPress,
          makeItSelected: this.makeThisSelected
        })
        
        if (log) {
          console.log('log', name, this._focus_id,'has become focusable')
        }
      } catch (e) {
        return false
      }
    }

    componentWillUnmount() {
      document.withNavigation?.removeFocusable(this._focus_id)
    }
    
    handleClick = e => {
      e.preventDefault()
      e.stopPropagation()
      if (this.callback && this.state.selected) this.callback()
    }

    handlePress = () => {
      if (this.callback) this.callback()
    }

    setCallback = fn => { this.callback = fn }

    makeThisSelected = selected => {
      if (this.itemEl.current) this.setState({ selected: selected })
    }

    makeThisDeleted = () => document.withNavigation?.removeFocusable(this._focus_id)

    render() {
      const { selected, ...rest } = this.props
      const isSelected = selected || this.state.selected

      return (
        <Wrapped
          {...rest}
          ref={this.itemEl}
          selected={isSelected}
          setCallback={this.setCallback}
          makeThisFocusable={this.makeThisFocusable}
          makeThisDeleted={this.makeThisDeleted}
          // onClick={this.handleClick}
          _focus_id={this._focus_id}
          _area_id={this.context?.areaId}
        />
      )
    }
  }

  Focusable.displayName = `Focusable(${getDisplayName(WrappedComponent)})`
  return Focusable
}

export default focusable

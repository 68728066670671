import React, { Component } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { format, parseISO } from 'date-fns'
import { observable, action } from 'mobx'
import { observer, inject } from 'mobx-react'

import { theme, Block, Avatar, Icon, Text, Modal, Button, ButtonsRow, Marquee, ListItem, ScrollableText } from 'UI'
import config from 'utils/config'

const pulse = keyframes`
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1.0);
  }
`
const pulseAnimation = css`
  animation: ${pulse} 1.2s ease-in-out infinite;
`

const deleting = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(50%);
    opacity: 0.01;
  }
`

const deletingAnimation = css`
  animation: ${deleting} 500ms ease-in-out 1;
`

const MessageContainer = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
  opacity: 1;
  ${p => p.deleting && deletingAnimation};
  overflow: hidden;
`

const StyledAvatar = styled(Avatar)`
  flex: 0 0 5vw;
  width: 7vw;
  height: 7vw;
  margin-right: ${theme.interval()};
`

const MessageContent = styled.div`
  display: flex;
  margin-right: ${theme.interval()};
  flex: 1 1 auto;
`

const IconWrap = styled(Block)`
  flex: 0 0 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.interval()};
  ${p => p.selected && pulseAnimation}
`

const StyledIcon = styled(Icon)`
  font-size: 4vw;
`

const Texts = styled.div`
  flex: 1 1 auto;
`

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  width:100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: ${theme.interval()};
`

const ModalHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  height: 100%;
`

const Title = styled.div`
  display: flex;
  margin-bottom: ${theme.interval()};
`

const Date = styled.div`
  margin-top: ${theme.interval()};
  text-align: right;
`
const DateModal = styled.div`
  text-align: right;
  min-width: 18vw;
  align-self: start;
  margin-left: auto;
`

const MessageModal = styled.div`
  min-width: 55vw;
  padding: ${theme.interval(2)};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: start;
`


const MessageModalDeleting = styled(Modal)`
  padding: ${theme.padding};
`
const MessageModalContent = styled.div`
  margin-top: ${theme.interval()};
  display: flex;
  margin-right: ${theme.interval()};
  flex: 1 1 auto;
  width:100%;
  height: 100%;
`

const PopAvatar = styled(Avatar)`
  width: 10vw;
  height: 10vw;
  flex: 0 0 5vw;
  margin-right: ${theme.interval()};
`

const ModalText = styled.div``

const Buttons = styled(ButtonsRow)`
  margin: ${theme.interval(2, 0, 0, 0)};
`

const ButtonsModal = styled(ButtonsRow)`
  margin: ${theme.interval(2, 0, 0, 0)};
  display: flex;
  align-self :center;
`

const notSelectedDelete = css`
  background: ${theme.colors.redRgba(0.6)};
`

const selectedDelete = css`
  background: ${theme.colors.redRgba(0.9)};
`

const stylesDelete = {
  notSelected: notSelectedDelete,
  selected: selectedDelete
}

const DeleteButton = styled(Button)`
  margin-left: ${theme.interval(2)};
  color: white;
`

const MessageMarquee = styled(Marquee)`
  width: 30vw;
  font-size: 8vh;
`
const DeletingTitle = styled(Text.H2)`
  flex: 1;
  align-self :center;
  margin-top: ${theme.interval(4)};
`


const StyledButtonsRow = styled(ButtonsRow)`
  margin-top: ${theme.interval(2)};
`

const TEXT = 'Be one.When one feels volume and chaos, one is able to acquire attitude.One occult issue i give you: ' +
  'believe each other.One inward moonlight i give you: capture each other.Who can hear the intuition and faith of a '

@inject('sharedStore','messagesStore')
@observer
class MessagesItem extends Component {

  @observable isModalOpen = false
  @observable isModalDeletingOpen = false

  @observable deleting = false

  handleStopRead = () => {
    this.props.sharedStore.stopReadText()
  }

  handleReadText = () => this.props.sharedStore.isNowReading ? this.handleStopRead() : this.handleStartRead()

  componentWillUnmount() {
    this.handleStopRead()
  }

  
  componentDidUpdate(prevProps){
    const { sharedStore } = this.props
    const allTextBeenRead = sharedStore.allTextBeenRead
    if (allTextBeenRead){
      document.withNavigation?.pressKey('arrowRight')
    }
  }

  deleteMessage = () => {
    const { messagesStore, id, focusOrder } = this.props
    this.deleting = true
    this.toggleModalDeletingOpen()
    this.toggleModal()
    setTimeout(() => {
      messagesStore.deleteMessage(id)
      document.withNavigation.nextWith('focusOrder').equals(focusOrder + 1)
    }, 500)
  }

  markReadMessage = () => {
    const { messagesStore, id, recipient_status_display } = this.props
    const isNew = recipient_status_display === 'New'
    if (isNew) messagesStore.markReadMessage(id)
  }

  @action
  toggleModal = () => {
    this.isModalOpen = !this.isModalOpen
    this.markReadMessage()
    if(!this.isModalOpen){
      this.handleStopRead()
    }
  }


  @action toggleModalDeletingOpen = () => {
    this.toggleModal()
    this.isModalDeletingOpen = !this.isModalDeletingOpen
  }


  handleStartRead = () => {
    const { text, sharedStore } = this.props
    sharedStore.readText(text)
    this.markReadMessage()
  }

  render() {
    const { sharedStore } = this.props
    const { selected, recipient_status_display, sender, text, created_at, ...rest } = this.props
    const isReaded = recipient_status_display !== 'New'
    return (
      <MessageContainer deleting={this.deleting} onPress={this.toggleModal} {...rest}>
        <MessageContent>
          <StyledAvatar src={sender.photo}/>
          <Texts>
            <Title><Text.H2 bold>{sender.name}</Text.H2><Text.H2>&nbsp;says:</Text.H2></Title>
            <MessageMarquee run={selected}><Text.H1>{text}</Text.H1></MessageMarquee>
            <Date><Text.H2>{`at ${format(parseISO(created_at), config.dateTimeFormat)}`}</Text.H2></Date>
          </Texts>
        </MessageContent>
        <IconWrap selected={selected}>
          <StyledIcon icon={!isReaded ? 'envelope' : 'envelope-open'} color={!isReaded && theme.colors.red}/>
        </IconWrap>
        {this.isModalDeletingOpen && (
          <MessageModalDeleting   onBack={this.toggleModalDeletingOpen} shouldntStoreFocusable>
            <DeletingTitle bold>{'Are you sure?'}</DeletingTitle>
              <StyledButtonsRow>
              <Button text='yes' icon='trash' red onPress={this.deleteMessage}/>
              <Button text='no' icon='times' dark first focusOrder={0.1} onPress={this.toggleModalDeletingOpen} />
            </StyledButtonsRow>
          </MessageModalDeleting>
          )
        }
        {this.isModalOpen && (
          <Modal onBack={this.toggleModal}>
            <MessageModal>
              <ModalHeader>
                  <PopAvatar src={sender.photo}/>
                  <Text.H2>{sender.name}</Text.H2>
                  <DateModal><Text.H3 thin>{`at ${format(parseISO(created_at), config.dateTimeFormat)}`}</Text.H3></DateModal>
              </ModalHeader>
              <ScrollableText onDeletePress={this.toggleModalDeletingOpen} isNowReading={sharedStore.isNowReading} onReadPress={this.handleReadText} onClosePress={this.toggleModal}>
                <Text.H2 thin>{text}</Text.H2>
              </ScrollableText>

            </MessageModal>
          </Modal>
        )}
      </MessageContainer>
    )
  }
}

export default MessagesItem

import React, { Component } from 'react'
import styled from 'styled-components'

import Action from './Action'
import { theme } from 'UI/index'

const ActionsContainer = styled.div`
  position: absolute;
  right: ${theme.interval(-2.1)};
  top: 50%;
  transform: translateY(-50%);
`

class Actions extends Component {
  render() {
    return (
      <ActionsContainer {...this.props} />
    )
  }
}

Actions.Item = Action
export default Actions

import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { observer } from 'mobx-react'

import { BoxesItem } from 'components/Boxes'

const PhotosItemContainer = styled(BoxesItem)`
`

@observer
class PhotosItem extends Component {
  
  handlePress = () => {
    const { onPress, id } = this.props
    onPress(id)
  }

  render() {
    const { onPress, ...props } = this.props
    return (
      <PhotosItemContainer {...props} onPress={this.handlePress} />
    )
  }
}

export default PhotosItem

export const ENVIRONMENTS = {
    production: 'production',
    development: 'development',
    staging: 'staging',
    insideTv: 'insidetv',
};

export const tvAppInfoFields = {
    env: 'env',
    tvVersion: 'tvVersion',
    isVersionVisible: 'isVersionVisible',
    ipAddress: 'ipAddress',
    apiUrl: 'apiUrl',
};

export const jsBridgeFunctions = {
    getAppInfo: 'getAppInfo',
    jsReady: 'jsReady',
    setSystemVolume: 'setSystemVolume',
    startYoutubeVideo: 'startYoutubeVideo',
    openPage: 'openPage',
    readText: 'readText',
    switchToHdmi: 'switchToHdmi',
    toast: 'toast',
    dial: 'dial',
    learn: 'learn',
    exitLearn: 'exitlearn',
    openSettings: 'openSettings',
}
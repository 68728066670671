import MainComponent from './Layout'
import StatusHandler from './StatusHandler'
import MainRoutes from './MainRoutes'
import Header from './Header'

export {
  MainComponent,
  StatusHandler,
  MainRoutes,
  Header
}

export default MainComponent

import React, { Component } from 'react'
import styled from 'styled-components'
import { observable } from 'mobx'
import { observer, inject } from 'mobx-react'
import formatFns from 'date-fns/format'

import config from 'utils/config'
import theme from 'UI/theme'
import Version from "../Version";

const StyledClock = styled.div`
  display: ${(props)=> props.welcome ? '-webkit-inline-box;' : 'flex' }
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: nowrap;
`
const WeekPart = styled.div`
  white-space: nowrap;
`
const DatePart = styled.div`
  white-space: nowrap;
  margin-right: 10px;
  text-align: end
`
const TimePart = styled.div`
  white-space: nowrap;
  text-align: end;
`

const StyledVersion = styled(Version)`
  //font-weight: 300;
  //position: absolute;
  //bottom: ${theme.interval(-1.5)};
  // right: ${theme.padding};
  z-index: 4;
  color: #ddd;
  font-size: 16px;
`

@observer
class Clock extends Component {
  timer = null
  @observable time = null
  @observable date = null
  @observable week = null

  componentDidMount() {
    const {
      time, date, week,
      format,
      timeFormat,
      dateFormat,
      interval= 1000 || 60000
    } = this.props

    if (!time && !date) this.time = formatFns(new Date(), format || config.dateTimeFormat)
    if (time) this.time = formatFns(new Date(), format || timeFormat || config.timeAMPM)
    if (date) this.date = formatFns(new Date(), format || dateFormat || config.shortDateFormat)
    if (week) this.week = formatFns(new Date(), format || dateFormat || config.getDayofWeek)

    this.timer = setInterval(() => {
      this.time = formatFns(new Date(), date ? (format || timeFormat || config.timeAMPM) : (format || config.dateTimeFormat))
      this.date = formatFns(new Date(), format || dateFormat || config.shortDateFormat)
    }, interval)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    const { date, time, week, dateStyle, timeStyle, welcome, ...rest} = this.props

    return (
        <div>
          {welcome?
            <>
                                  {(!date || time) && <TimePart className={`clock`} style={timeStyle}>{this.time}</TimePart>}
                                  <StyledClock welcome {...rest}>
                                  {date && <DatePart welcome className={`date`} style={dateStyle}>{this.date}, </DatePart>}
                                  {week && <WeekPart className={`clock`} style={timeStyle}>{this.week}</WeekPart>}
                                  </StyledClock>
                                { this.props.withVersion === true && <StyledVersion /> }
                                </> :
                                <>
                                          <StyledClock {...rest}>
                                          {date && <DatePart className={`date`} style={dateStyle}>{this.date}</DatePart>}
                                          {(!date || time) && <TimePart className={`clock`} style={timeStyle}>{this.time}</TimePart>}
                                        </StyledClock>
                                        { this.props.withVersion === true && <StyledVersion /> }
                                        </>
          }
        </div>
    )
  }
}

export default Clock

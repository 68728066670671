import React, { Component, createRef } from 'react'
import styled from 'styled-components'
import { observable } from 'mobx'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router'

import { focusable, area } from 'components/withNavigation'
import jsbridge from 'utils/jsbridge'

import { SideMenuItem } from 'components/SideMenu'
import { theme, Text } from 'UI'

const StyledSideMenu = styled.div`
  flex: 0 0 24vw;
  width: 24vw;
  padding: 0 0 0 4vw;
  margin-top: ${theme.interval(2)};
  position: relative;
  z-index: 3;
  ${ p => p.selected && 'background: red;'}
`

const Menu = styled.div`
  transition: ${theme.transition};
`

const Logo = styled.div`
  text-align: center;
  color: white;
  margin-top: ${theme.interval(3.2)};
  letter-spacing: 1.4em;
  height: 4vh; // TODO Remove this placeholder 'height' for empty logo
`
const Menus = ({ menus, ...rest }) => menus.map(item => <SideMenuItem key={`menu_${item.href}`} {...item} {...rest} />)

@inject('pageStore')
@observer
class SideMenu extends Component {

  componentDidMount() {
    document.withNavigation?.forceRefresh()
  }

  handleMain = () => {
    const { history, pageStore } = this.props
    jsbridge.call('openPage', 'main')
    history.router.navigateTo('main')
    pageStore.goToPage('/')
  }

  handleTV = () => {
    jsbridge.call('switchToHdmi')
  }

  render() {
    const { pageStore, location, ...props } = this.props
    const isBackNeed = location.pathname !== `/main/${pageStore.mainId}`

    return (
      <StyledSideMenu {...props}>
        <Menu>
          {pageStore.isLoaded && (
            <>
              {isBackNeed && <SideMenuItem title="Main" icon="home" handlePress={this.handleMain} first href='/main/1' />}
              {!isBackNeed && <SideMenuItem title="Live TV" icon="tv" handlePress={this.handleTV} first href='liveTV' />}
              <Menus menus={pageStore.menus} />
            </>
          )}
        </Menu>
      </StyledSideMenu>
    )
  }
}

export default area(withRouter(SideMenu))

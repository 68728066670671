import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { observable } from 'mobx'
import { observer, inject } from 'mobx-react'
import { focusable } from 'components/withNavigation'
import { theme, Icon } from 'UI'

const selected = css`
  z-index: 1;
  transform: scale(1.2);
  box-shadow: ${theme.boxShadowSelected};
`

const notSelected = css`
  transform: scale(1);
  box-shadow: 0 3px 5px 1px rgba(0,0,0,0.2);
`

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: ${theme.interval(2)};
  padding-right: ${theme.interval()};
  transition: ${theme.transition};
  position: relative;
  border-radius: ${theme.borderRadius};
  ${p => p.selected ? selected : notSelected}
`

const InputField = styled.input`
  flex-grow: 1;
  color: ${theme.colors.base};
  font-size: ${theme.fontHeading3};
  border: none;
  margin: 0;
  &::placeholder {
    color: #dddddd;
  }
`

const EnterIcon = styled(Icon)`
  margin-left: ${theme.interval()};
  font-size: ${theme.fontHeading2};
  color: ${p => p.focus ? theme.colors.base : '#dddddd'}
`

@observer
class Input extends Component {

  @observable focus = false

  componentDidMount() {
    const { setCallback } = this.props
    if (setCallback) setCallback(this.onPress)
  }

  componentDidUpdate(prevProps) {
    const { selected } = this.props
    if (!selected && (selected !== prevProps.selected)) this.focus = false
  }

  onPress = () => {
    const { forwardedRef, onPress } = this.props
    if (!this.focus) {
      forwardedRef?.current?.focus()
      this.focus = true
    } else {
      if (onPress) onPress()
    }
  }

  render() {
    const { selected, forwardedRef, className, onPress, ...rest } = this.props

    return (
      <InputContainer selected={selected} className={className}>
        <InputField ref={forwardedRef} type="text" placeholder='Type something...' {...rest} />
        <EnterIcon focus={this.focus} icon='arrow-circle-right' />
      </InputContainer>
    )
  }
}

export default focusable(Input)

import React, { Component } from 'react'
import styled from 'styled-components'
import { observable } from 'mobx'
import { observer, inject } from 'mobx-react'
import { area } from 'components/withNavigation'

import { Boxes, BoxesItem } from 'components/Boxes'

import { theme, Icon } from 'UI'

const StyledBoxes = styled.div`
  transition: ${theme.transition};
  margin-left: 5vw;
`

const StyledBoxItem = styled(BoxesItem)`
  overflow: visible;
`

const StyledAlert = styled.div`
  position: absolute;
  top: -1vw;
  right: -1vw;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4vw;
  height: 4vw;
  border-radius: ${theme.borderRadius};
  font-size: ${theme.font.fontHeading2};
  color: ${theme.colors.red};
`

const Alert = props => <StyledAlert {...props}><Icon icon='bell'/></StyledAlert>

@inject('servicesStore')
@observer
class ServicesList extends Component {
  render() {
    const { servicesStore, ...rest } = this.props
    return (
      <StyledBoxes>
        {servicesStore.services.map((item, index) => (
          <StyledBoxItem
            className='boxItem'
            key={`box_${item.href}_${index}`}
            focusOrder={index + 1}
            {...rest}
            {...item}
            injectComponent={servicesStore.isRequested(item.title) ? <Alert icon='bell' /> : null}
            first
          />
        ))}
      </StyledBoxes>
    )
  }
}

export default area(ServicesList, { oneDimension: true })
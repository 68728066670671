import userStore from "../stores/userStore";
import * as Sentry from "@sentry/react";
import bi from "utils/BI";

const fetcher = {
  request(url, options) {
    return new Promise((resolve, reject) => {
      fetch(url, options)
        .then((response) => {
          if (!response) throw new ApiError("Bad response", response);
          return response.json();
        })
        .then((json) => {
          if (json.error) throw new ApiError("Json error", json);
          resolve(json);
        })
        .catch((error) => reject(error));
    });
  },
  default(url, customOptions = {}) {
    const token = userStore.token || localStorage.getItem("token");
    const { dontUseToken } = customOptions;
    let headers = new Headers();
    if (!dontUseToken) {
      headers = new Headers({
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `token ${token}`,
      });
    }
    const options = {
      headers,
      ...customOptions,
    };
    return this.request(url, options);
  },
  get(url, customOptions) {
    const options = {
      method: "GET",
      ...customOptions,
    };
    return this.default(url, options);
  },
  post(url, body, customOptions = {}) {
    const options = {
      method: "POST",
      body: JSON.stringify(body || customOptions.body || {}),
      ...customOptions,
    };
    return this.default(url, options);
  },
  put(url, body, customOptions = {}) {
    const options = {
      method: "PUT",
      body: JSON.stringify(body || customOptions.body || {}),
      ...customOptions,
    };
    return this.default(url, options);
  },
  delete(url, customOptions = {}) {
    const options = {
      method: "DELETE",
      ...customOptions,
    };
    return this.default(url, options);
  },
  getContacts() {
    return this.get(
      `${process.env.REACT_APP_API}api/v2/caregiver/contacts/${userStore.userData.id}`
    );
  },
  getPhotos() {
    return this.get(
      `${process.env.REACT_APP_API}caregiver/photos/${userStore.userData.id}`
    );
  },
  getPage(endpoint, body) {
    const mockbody = {
      section: "Indoor",
    };
    return this.post(
      `${process.env.REACT_APP_API}${endpoint}/${userStore.userData.id}`,
      mockbody
    );
  },
  //   upload(url, file, customOptions = {}) {
  //     const formData = new FormData()
  //     formData.append('file', file)
  //     const headers = new Headers({
  //       'X-Access-Token': loginStore.token || ''
  //     })
  //     const option = {
  //       headers,
  //       method: 'POST',
  //       body: formData
  //     }
  //     return fetch(url, option)
  //   }
};

export default fetcher;

class ApiError extends Error {
  constructor(obj, json) {
    super(obj);
    this.name = "ApiError";
    this.message = obj;
    this.detail = json;
    Sentry.captureMessage("ApiError " + this.message);
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(obj).stack;
    }
  }
}

class EndpointError extends Error {
  constructor(obj, msg) {
    super();
    this.name = "EndpointError";
    this.message = obj;
    console.error("EndpointError detail", obj, msg);
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(obj).stack;
    }
  }
}

class Fetcher {
  constructor(apis, endpoints) {
    this.apis = apis;
    this.defaultApi = Object.values(apis)[0];
    this.endpoints = endpoints;
  }

  get userId() {
    return userStore?.userId;
  }
  get token() {
    return userStore?.token;
  }
  apis = {};
  defaultApi = undefined;
  endpoints = {};
  method = "GET";
  body = null;
  query = null;
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
  };
  customOptions = {};
  error = false;

  // example: api.get.from.messages.all('almost')
  get proxy() {
    const that = this;
    return new Proxy(that.endpoints, {
      get(target, prop) {
        // target[props] === endpoints['messages']
        if (!target[prop])
          throw new EndpointError("There isn't such endpoint", target[prop]);
        return new Proxy(target[prop], {
          // target[props] === messages['all']
          get(subTarget, subProp) {
            if (!subTarget[subProp])
              throw new EndpointError(
                "There isn't such endpoint",
                target[prop]
              );
            return (...params) => {
              const all = [...params, that.userId]; // making from messages.all('almost') === messages.all('almost', userId)
              return that.call(that.endpoints[prop][subProp](...all)); // getting endpoint  - calling it with params for url string - and calling 'call' function with that string
            };
          },
        });
      },
    });
  }

  get from() {
    return this.proxy;
  }
  get to() {
    return this.proxy;
  }

  call = async (endpoint) => {
    if (!this.defaultApi) return false;

    const query = this.query
      ? Object.keys(this.query).reduce(
          (str, curr) => `${str}${curr}=${this.query[curr]}`,
          "?"
        )
      : "";
    const url = `${this.defaultApi}/${endpoint}${query}`.replace(
      /([^:]\/)\/+/g,
      "$1"
    );

    const body = this.body ? { body: JSON.stringify(this.body) } : {};
    const options = {
      method: this.method,
      ...body,
      headers: new Headers({
        ...this.headers,
        Authorization: `token ${this.token}`,
      }),
    };

    try {
      return await this.request(url, options);
    } catch (e) {
      this.error = true;
      return [];
    }
  };

  request = (url, options) => {
    const params = {
      userId: this.userId,
      url: url,
    };
    return new Promise((resolve, reject) => {
      fetch(url, options)
        .then((response) => {
          if (!response.ok)
            throw new ApiError("Bad response " + response.url, response);
          return response.json();
        })
        .then((json) => {
          if (json.error) throw new ApiError("Json error", json);
          // TODO: Remove BI
          // bi.send.log.with('NETWORK_REQUEST', options.method, params)

          this.error = false;
          resolve(json);
        })
        .catch((error) => {
          // TODO: Remove BI
          // bi.send.error.with('NETWORK_REQUEST', options.method, { ...params, error: error })
          reject();
        });
    });
  };

  withOptions = (customOptions) => {
    this.customOptions = customOptions;
    return this;
  };

  get(query) {
    this.method = "GET";
    this.body = null;
    if (query) this.query = query;
    else this.query = null;
    return this;
  }

  post = (body) => {
    this.method = "POST";
    this.body = body || {};
    return this;
  };

  put = (body) => {
    this.method = "PUT";
    this.body = body || {};
    return this;
  };

  delete() {
    this.method = "DELETE";
    return this;
  }
}

const apis = {
  //TODO now changing 'api' dont work - used always 'cms'
  cms: window.location.origin.includes("staging")
    ? "https://backend.staging.sparko.tv/"
    : process.env.REACT_APP_API,
  ip: process.env.REACT_APP_IP_API,
  video: "http://video.io/",
};

const endpoints = {
  messages: {
    all: (userId) => `api/v2/messages/${userId}/?filter=dismiss`,
    read: (userId) => `api/v2/messages/${userId}/view/`,
    delete: (userId) => `api/v2/messages/${userId}/dismiss/`,
  },
  page: {
    all: () => `api/v2/tv-menu/`,
  },
  user: {
    login: () => `api/v2/auth/login_by_email/`,
    pin: () => `api/v2/auth/send_pin/`,
    profile: () => `api/v2/profile/`,
  },
  contacts: {
    all: (userId) => `api/v2/caregiver/contacts/${userId}/`,
  },
  photos: {
    all: (userId) => `caregiver/photos/${userId}/`,
  },
  content: {
    saved: (userId) => `content/user/${userId}/saved`,
    forSuggestions: () => `api/v2/content/suggested/`,
    save: (itemId, userId) => `content/save/user/${userId}/content/${itemId}`,
    delete: (itemId, userId) => `content/save/user/${userId}/content/${itemId}`,
    new: (contentId) => `api/v3/content-by-tv-menu/${contentId}/`,
    get: (contentId) => `api/v4/online-classroom/signed_url/${contentId}/`,
  },
  preference: {
    all: () => `api/v2/preferences`,
    like: (preferenceId, like) =>
      `api/v2/preferences/${preferenceId}/?liked=${like}`,
  },
  services: {
    all: () => `api/v3/butlers/`,
    requestService: () => `api/v2/butlers/requests/`,
    handled: (userId) => `api/v2/butlers/requests/${userId}/?filter=handled`,
  },
  events: {
    fromTo: (from, to, userId) =>
      `api/v2/calendar/${userId}/date-range/${from}/${to}/`,
    delete: (eventId, userId) => `api/v2/calendar/${userId}/by-id/${eventId}`,
  },
  newrow: {
    forContent: (contentId) => `api/v2/newrow/signed_url/${contentId}/`,
  },
};

export const api = new Fetcher(apis, endpoints);

import React, { Component } from 'react'
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'

import { MessagesItem } from 'components/Messages'

import { theme, List } from 'UI'

@inject('messagesStore', 'pageStore')
@observer
class Messages extends Component {

  componentDidMount() {
    const { messagesStore, pageStore } = this.props
    messagesStore.getMessages()
    pageStore.setTitle('Messages')
  }

  render() {
    const { messagesStore, ...props } = this.props
    return (
      <List>
        {messagesStore.messages.map((item, index) => <MessagesItem key={`message_${item.id}`} first focusOrder={index + 1} {...item} {...props} name={`message_${item.id}`} />)}
      </List>
    )
  }
}

export default Messages

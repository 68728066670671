import React, { Component } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { format, isSameMinute, parseISO } from 'date-fns'
import tinycolor from 'tinycolor2'
import { action, observable } from 'mobx'
import { observer, inject } from 'mobx-react'

import { theme, ListItem, Text, Img, Modal, Button, ButtonsRow, Avatar, Marquee } from 'UI'

import getImageSrc from 'utils/RemindersIcons'
import config from 'utils/config'
import {cutTextWhenNeeded } from 'utils/utilFunctions'

const pulse = keyframes`
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1.0);
  }
`
const animation = css`
  animation: ${pulse} 1.2s ease-in-out infinite;
`

const DiaryEventContainer = styled(ListItem)`
  display: flex;
  z-index: 0;
  justify-content: space-between;
  align-items : flex-start;
  transition: ${theme.css.transition};
  ${p => p.deleting ? 'max-height: 1px; opacity: 0.01;' : 'max-height: 300px; opacity: 1;'};
`

const FromTo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Time = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const From = styled(Time)`
`
const To = styled(Time)`
  margin-top: ${theme.interval()};
`
const ToFromModal = styled(Time)`
  margin-top: ${theme.interval()};
  margin-bottom: ${theme.interval()};

`
const FromToText = styled(Text.H2)`
  margin-right: ${theme.interval(0.5)};
`

const Title = styled(Text.H2)`
  flex: 1;
  margin-bottom: ${theme.interval()};
`
const DeletingTitle = styled(Text.H2)`
  flex: 1;
  align-self :center;
  margin-top: ${theme.interval(4)};
`

const ImgWrap = styled.div`
  background: linear-gradient(to right bottom, ${tinycolor(theme.colors.orange).lighten(10).toHexString()}, ${tinycolor(theme.colors.orange).darken(10).toHexString()});
  border-radius: ${theme.borderRadius};
  padding: ${theme.interval(1, 1)};
  margin-left: ${theme.interval(2)};
  align-self: center;
  box-shadow: ${theme.boxShadow};
  width: ${theme.interval(5)};
  height: ${theme.interval(5)};
  ${p => p.selected && animation};
`

const ReminderIcon = styled(Img)`
  width: ${theme.interval(5)};
`

const DiaryEventModal = styled(Modal)`
  padding: ${theme.padding};
`

const DiaryEventDeletingModal = styled(Modal)`
  padding: ${theme.padding};
`
const Who = styled(Text.H2)`
  text-align: center;
`

const StyledAvatar = styled(Avatar)`
  display: block;
  align-self: center;
`

const DiaryEventMarquee = styled(Marquee)`
  flex: 1;
  margin: ${theme.interval(0.7, 0)};
`

const StyledButtonsRow = styled(ButtonsRow)`
  margin-top: ${theme.interval(2)};
`

@inject('sharedStore','eventsStore')
@observer
class DiaryEvent extends Component {

  @observable isModalOpen = false
  @observable isModalDeletingOpen = false

  @observable deleting = false

  @action toggleModalOpen = () => {
    this.isModalOpen = !this.isModalOpen
    this.handleStopRead()
  }

  @action toggleModalDeletingOpen = () => {
    this.toggleModalOpen()
    this.isModalDeletingOpen = !this.isModalDeletingOpen
  }
  
  componentWillUnmount(){
    this.handleStopRead()
  }

  handleStartRead = () => {
    const { description, title, from: fromProp, to: toProp ,sharedStore} = this.props

    const from = format(new Date(fromProp.official[0]), config.longDateFormat)
    const to = format(new Date(toProp.official[0]), config.longDateFormat)
    const isSame = isSameMinute(parseISO(fromProp.official[0]), parseISO(toProp.official[0]))

    const date = isSame ? `in ${from}` : `from ${from} to ${to}`
    const text = `${description || title}. ${date}`
    sharedStore.readText(text)
  }

  handleStopRead = () => {
    this.props.sharedStore.stopReadText()
  }

  handleReadText = () => this.props.sharedStore.isNowReading ? this.handleStopRead() : this.handleStartRead()

  deleteEvent = () => {
    const { eventsStore, focusOrder, id} = this.props
    this.deleting = true
    this.toggleModalDeletingOpen()
    this.toggleModalOpen()
    setTimeout(() => {
      eventsStore.deleteEvent(id)
      document.withNavigation.nextWith('focusOrder').equals(focusOrder + 1)
    }, 300)
  }

  render() {
    const { sharedStore } = this.props
    const { from: fromProp, to: toProp, title, reminder_type_display, selected, description, creator_profile, ...rest } = this.props
    const { photo_url, name } = creator_profile

    const from = format(new Date(fromProp.official[0]), config.longDateFormat)
    const to = format(new Date(toProp.official[0]), config.longDateFormat)
    const isSame = isSameMinute(parseISO(fromProp.official[0]), parseISO(toProp.official[0]))
    
    return (
      <DiaryEventContainer {...rest} onPress={this.toggleModalOpen} deleting={this.deleting}>
        <FromTo>
          <DiaryEventMarquee run={selected}>{cutTextWhenNeeded(title,40)}</DiaryEventMarquee>
          <From><FromToText>{isSame ? 'In' : 'From'}</FromToText><Text.H2 bold>{from}</Text.H2></From>
          {!isSame && <To><FromToText>Until</FromToText><Text.H2 bold>{to}</Text.H2></To>}
        </FromTo>
        <ImgWrap selected={selected}>
          <ReminderIcon src={getImageSrc(reminder_type_display.toLowerCase())} />
        </ImgWrap>
        {this.isModalDeletingOpen && (
          <DiaryEventDeletingModal   onBack={this.toggleModalDeletingOpen} shouldntStoreFocusable>
            <DeletingTitle bold>{'Are you sure?'}</DeletingTitle>
              <StyledButtonsRow>
              <Button text='yes' icon='trash' red onPress={this.deleteEvent}/>
              <Button text='no' icon='times' dark first focusOrder={0.1} onPress={this.toggleModalDeletingOpen} />
            </StyledButtonsRow>
          </DiaryEventDeletingModal>
          )
        }
        {this.isModalOpen && (
          <DiaryEventModal onBack={this.toggleModalOpen}>
            <StyledAvatar src={photo_url} />
            <Who><b>{name}</b> reminds:</Who>
            <Title thin>{description || title}</Title>
            <From><FromToText >{isSame ? 'In' : 'From'}</FromToText><Text.H2 bold>{from}</Text.H2></From>
          {!isSame && <ToFromModal><FromToText>Until</FromToText><Text.H2 bold>{to}</Text.H2></ToFromModal>}
            <StyledButtonsRow>
              <Button text='delete' icon='trash' red onPress={this.toggleModalDeletingOpen} />
              <Button text={sharedStore.isNowReading ? 'Stop' : 'Read'} icon={sharedStore.isNowReading? 'volume-off' : 'volume-up'} onPress={this.handleReadText} dark />
              <Button text='close' icon='times' dark first focusOrder={0.5} onPress={this.toggleModalOpen} />
            </StyledButtonsRow>
          </DiaryEventModal>
        )}
      </DiaryEventContainer>
    )
  }
}

export default DiaryEvent

import {
  observable,
  action,
  computed,
  autorun,
  toJS,
  when,
  reaction,
} from "mobx";
import { fromPromise } from "mobx-utils";
import memoizee from "memoizee";
import jsbridge from "utils/jsbridge";

const toMemoizee = (contentId, content) =>
  content.find((item) => item?.contentId === parseInt(contentId));
const findInContent = memoizee(toMemoizee);

import { api } from "utils/fetcher";
import { withStatusFor, updateIn } from "utils/storeHelpers";

class ContentStore {
  constructor() {
    reaction(
      () => this.content.length > 0,
      (isNotZero) => {
        if (isNotZero) document.withNavigation.forceRefresh();
      }
    );
  }

  @observable _content = [];
  @computed get content() {
    return this._content?.value?.some((a) => a.content_order)
      ? this._content?.value?.sort(
          (a, b) => a.content_order - b.content_order
        ) || []
      : this._content?.value || [];
  }
  @computed get link() {
    return this._content?.value;
  }
  @observable _currentContent = null;
  @computed get currentContent() {
    return toJS(this._currentContent) || {};
  }

  @action
  loadContentFor = (page) => {
    if (page === "saved-content" || page === "saved-activities") {
      const query = { type: page === "saved-content" ? "content" : "event" };
      this._content = fromPromise(api.get(query).from.content.saved());
    } else if (page === "suggestions") {
      this._content = fromPromise(api.get().from.content.forSuggestions());
    } else if (
      page === "suggestions-content" ||
      page === "suggestions-activities"
    ) {
      const query = {
        type: page === "suggestions-content" ? "content" : "event",
      };
      this._content = fromPromise(api.get(query).from.content.forSuggestions());
    } else {
      this._content = fromPromise(api.get().from.content.new(page));
    }
  };

  saveContent = async (contentId) => {
    const res = await api.post().to.content.save(contentId);
    if (res.status === "ok") {
      jsbridge.call("toast", "Item saved!");
      updateIn(this.link).where("content_id", contentId).to("saved", true);
    }
  };

  @action
  removeContent = async (contentId) => {
    const res = await api.delete().to.content.delete(contentId);
    if (res.status === "ok") {
      jsbridge.call("toast", "Item removed!");
      updateIn(this.link).where("content_id", contentId).to("saved", false);
    }
  };

  @action
  clearContent = () => (this._content = []);

  //TODO Remove this method
  contentFor = (contentId) => {
    const newContent = this.content.find(
      (item) => item.content_id === parseInt(contentId)
    );
    this._currentContent = newContent;
    return newContent || {};
  };

  getContent = async (contentId) => {
    const res = await api.post().from.content.get(contentId);
    return res;
  };

  @action
  setCurrentFor = (contentId) => {
    return (this._currentContent =
      this.content.find((item) => item.content_id === parseInt(contentId)) ||
      {});
  };

  memoCurrentContent = (contentId) => findInContent(contentId, this.content);

  youtubeCallback = null;
  setYoutubeCallback = (fn) => {
    this.youtubeCallback = fn;
  };

  setYouTubePlayData = (contentYoutubeId, timePlayed) => {
    if (this.youtubeCallback)
      this.youtubeCallback(contentYoutubeId, timePlayed);
    localStorage.setItem(
      `youtube_${contentYoutubeId}`,
      JSON.stringify(timePlayed)
    );
  };
}
export const contentStore = new ContentStore();
export default withStatusFor(contentStore, {
  withMainField: "_content",
  onPath: "main/.*/content",
});

import React, { Component } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { focusable } from 'components/withNavigation'
import jsbridge from 'utils/jsbridge'

import { theme, Avatar, Text, Icon, Block, ListItem } from 'UI'

const pulse = keyframes`
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1.0);
  }
`
const animation = css`
  animation: ${pulse} 1.2s ease-in-out infinite;
`

// const selected = css`
//   box-shadow: ${theme.boxShadowSelected};
//   transform: scale(1.2);
//   z-index: 1;
// `
//
// const notSelected = css`
//   box-shadow: ${theme.boxShadow};
//   transform: scale(1)};
// `

const ContactContainer = styled(ListItem)`
  display: flex;
  padding: ${theme.padding};
  justify-content: space-between;
  align-items: center;
  //z-index: 0;
  // ${p => p.selected ? selected : notSelected}
`

const StyledAvatar = styled(Avatar)`
  flex: 0 0 5vw;
`

const Texts = styled.div`
  display: flex;
  align-items: center;
`

const CallTo = styled(Text.H3)`
  opacity: 0.8;
  margin-left: ${theme.interval()};
`

const Name = styled(Text.H3)`
  margin-left: ${theme.interval()};
`
const IconWrap = styled(Block)`
  flex: 0 0 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.interval()};
  ${p => p.selected && animation}
`

const StyledIcon = styled(Icon)`
  font-size: 4vw;
  color: ${theme.colors.greenRGBA(0.9)};
`

class ContactsItem extends Component {

  dial = () => {
    const { user_id, user_name, user_photo } = this.props
    const callMember = {
      id: user_id,
      type: 'CAREGIVER',
      name: user_name,
      avatar: user_photo,
    };
    jsbridge.call('dial', JSON.stringify(callMember), true, true)
  }

  render() {
    const { selected, user_name, photo, ...rest } = this.props

    return (
      <ContactContainer onPress={this.dial} {...rest}>
        <Texts>
          <StyledAvatar src={photo} />
          <Name bold>{user_name}</Name>
        </Texts>
        <IconWrap selected={selected}><StyledIcon icon='video' /></IconWrap>
      </ContactContainer>
    )
  }
}

export default ContactsItem

import React, { Component,cloneElement } from 'react'
import styled, { css, keyframes } from 'styled-components'
import tiny from 'tinycolor2'
import { withRouter } from 'react-router'
import { snakeCase } from 'change-case'
import { observable } from 'mobx'
import { observer, inject } from 'mobx-react'

import { focusable } from 'components/withNavigation'

import { theme } from 'UI'

const pulse = keyframes`
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.1);
  }
`

const selected = css`
  box-shadow: ${theme.boxShadowSelected};
  transform: scale(1.1);
  animation: ${pulse} 1.2s ease-in-out infinite;
  animation-delay: 300ms;
  z-index: 10;
`

const notSelected = css`
  box-shadow: ${theme.boxShadow};
  transform: scale(1);
  z-index: 1;
`

const BoxContainer = styled.div`
  position: relative;
  border-radius: ${theme.borderRadius};
  overflow: hidden;
  transition: all 300ms ease-in-out;
  transform: scale(1);
  ${p => p.selected ? selected : notSelected};
  
  display: inline-block;
  width: 18vw;
  height: 18vw;
  margin-bottom: 2.2vw;
  margin-right: 2.2vw;
  &:nth-child(3n) {
    margin-right: 0;
  }
`

const ImgWrap = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: ${theme.borderRadius};
`

const BackgroundImage = styled.div`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position-x: center;
  ${props => props.src && `background-image: url(${props.src})`};
`

const Title = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  color: #fff;
  line-height: 1.6vw;
  font-size: 1.5vw;
  font-weight: 700;
  padding: 1vw;
  width: 100%;
  background: ${props => props.color ? `${tiny(props.color).setAlpha(0.85).toRgbString()}` : 'rgba(50,50,50,0.85)'};
  border-bottom-left-radius: ${theme.borderRadius}; 
  border-bottom-right-radius: ${theme.borderRadius};
`

@inject('pageStore')
@observer
class BoxesItem extends Component {

  componentDidMount() {
    const { setCallback, onPress } = this.props
    setCallback(onPress || this.goToHrefBox)
  }

  goToHrefBox = () => {
    const { id, href, history, pageStore, ...rest } = this.props
    const getPath = href => {
      if (href === 'boxes-list') return id
      if (href === 'content-list') return `${id}/content`
      if (href === 'suggestions') return `${href}/content` //FIX Hardcoded
      if (href === 'suggestions-content') return `${href}/content`
      if (href === 'suggestions-activities') return `${href}/content`
      if (href === "saved-activities") return `${href}/content`
      if (href === "saved-content") return `${href}/content`
      return href
    }
    
    history.push(`/main/${getPath(href)}`)
  }

  render() {
    const { className, forwardedRef, selected, title, photo, image, color, pageColor, injectComponent } = this.props
    return (
      <BoxContainer className={className} selected={selected} ref={forwardedRef} name={`Box_${title}`}>
        {injectComponent && cloneElement(injectComponent,{selected})}
        <ImgWrap>
          <BackgroundImage src={photo || image} />
        </ImgWrap>
        {title && <Title color={color || pageColor}>{title?.toUpperCase()}</Title>}
      </BoxContainer>
    )
  }
}

export default focusable(withRouter(BoxesItem))

import React, { Component } from "react";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import * as Sentry from "@sentry/react";

import "weather-icons/css/weather-icons.min.css";
import bg from "assets/images/welcome_bg.jpg";

import {
  jsBridgeFunctions,
  jsBridgeFunctionsEnums,
} from "../../utils/jsbridgeConstants";

import tinycolor from "tinycolor2";
import jsbridge from "utils/jsbridge";

import Clock from "components/Clock";
import { theme, Button, Text, Icon } from "UI";

function getTimeGreeting(date) {
  const currentHour = date.getHours();
  if (currentHour >= 6 && currentHour < 11) return "Good Morning";
  if (currentHour >= 11 && currentHour < 16) return "Good Afternoon";
  if (currentHour >= 16 && currentHour < 19) return "Good Evening";
  return "Good Night";
}

function getDailySentence(date) {
  const currentHours = date.getHours();
  if (currentHours >= 6 && currentHours < 11)
    return "Did you know that a healthy breakfast is important for an energetic day? Bon Appetit!";
  if (currentHours >= 11 && currentHours < 16) return "Enjoy your lunch!";
  if (currentHours >= 16 && currentHours < 19)
    return "A light dinner is a great way to wind down the day.";
  return "Good night!";
}

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100vw;
  height: 100vh;
  padding: ${theme.padding};
  background: linear-gradient(
    to right bottom,
    ${tinycolor("rgba(80, 47, 95, 0.96)").lighten(40).toHexString()},
    ${tinycolor("rgba(80, 47, 95, 0.96)").darken(10).toHexString()}
  );
`;

const BackgroundContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: ${theme.padding};
  border-radius: ${theme.interval()};
  overflow: hidden;
`;

const BackgroundImage = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url(${bg});
  background-size: cover;
  filter: blur(15px);
`;

const WeatherContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Temp = styled.div`
  margin-left: ${theme.interval()};
`;

const WeatherIcon = styled.div`
  font-size: ${theme.fontHeading1};
`;

const StyledClock = styled(Clock)`
  flex-direction: column;
  align-items: center;
  .date {
    font-size: ${theme.fontHeading2};
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  padding: ${theme.padding};

  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;

const Header = styled.div`
  font-size: 6vh;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 1vh;
  padding: 0 ${theme.padding};
  position: relative;
`;
const Title = styled.div``;
const Footer = styled.div`
  margin-bottom: 2.3vh;
  display: flex;
  justify-content: center;
`;

const DailyContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
`;

const Label = styled(Text.H3)`
  color: white;
  margin-right: 1vw;
`;

const Field = styled.div`
  width: 50vw;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: center;
  margin-bottom: ${theme.interval(1.5)};
`;

const ButtonStart = styled(Button)`
  margin-bottom: ${theme.interval(3)};
`;
const OtherButton = styled(Button)`
  width: 20vw;
`;

const GreetingsTextH2 = styled(Text.H2)`
  font-size: 8vh;
`;
const GreetingsTextH3 = styled(Text.H2)`
  font-size: 7vh;
`;

const UserGreetings = ({ username = "", date = new Date() }) => (
  <DailyContainer>
    <GreetingsTextH2>{getTimeGreeting(date)}</GreetingsTextH2>
    <GreetingsTextH3 bold>{username}</GreetingsTextH3>
    <GreetingsTextH2 thin>{getDailySentence(date)}</GreetingsTextH2>
  </DailyContainer>
);

const Background = () => (
  <BackgroundContainer>
    <BackgroundImage />
  </BackgroundContainer>
);
const Weather = ({ data, ...props }) =>
  data ? (
    <WeatherContainer>
      <WeatherIcon className={data.icon} />
      <Temp>{`${data.temp}℃`}</Temp>
    </WeatherContainer>
  ) : (
    <div>{` `}</div>
  );

@inject(
  "messagesStore",
  "eventsStore",
  "welcomeStore",
  "userStore",
  "pageStore"
)
@observer
class WelcomeScreen extends Component {
  constructor() {
    super();
    document.withNavigation.forceRefresh();
  }

  componentDidMount() {
    const { welcomeStore, pageStore, userStore } = this.props;
    welcomeStore.getWeather();
    pageStore.getPage();
    userStore.getProfile();
    Sentry.setUser({ id: userStore.userId, username: userStore.username });
    Sentry.setTag("id", userStore.userId);
    Sentry.setTag("username", userStore.username);
  }

  goTo = (link) => () => {
    const { history } = this.props;
    jsbridge.call("openPage", link);
    history.router.navigateTo(link);
  };

  render() {
    const { welcomeStore, userStore, messagesStore, eventsStore } = this.props;
    const messagesCount = messagesStore.onlyNewMessages().length;
    const eventsCount = eventsStore.todayNotHappenedYet().length;

    return (
      <Container>
        <Background />
        <Content>
          <Header>
            <Weather data={welcomeStore.weather} />
            <StyledClock date time week withVersion welcome />
          </Header>
          <Title>
            <UserGreetings username={userStore.username} />
          </Title>
          <Footer>
            <OtherButton
              text="Messages"
              icon="envelope"
              badge={messagesCount}
              onPress={this.goTo("messages")}
            />
            <ButtonStart
              id="startButton"
              first
              text="Start"
              onPress={this.goTo("main")}
              marginLeft
              marginRight
            />
            <OtherButton
              text="reminders"
              icon="bell"
              badge={eventsCount}
              onPress={this.goTo("diary")}
            />
          </Footer>
        </Content>
      </Container>
    );
  }
}

export default withRouter(WelcomeScreen);

import React, { Component } from 'react'
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'

import { ContactsItem } from 'components/Contacts'

import { List } from 'UI'


@inject('contactsStore', 'pageStore')
@observer
class Contacts extends Component {

  componentDidMount() {
    const { contactsStore, pageStore } = this.props
    contactsStore.getContacts()
    pageStore.setTitle('Contacts')
  }

  render() {
    const { contactsStore, ...props } = this.props
    return (
      <List>
        {contactsStore.contacts.map(item => <ContactsItem key={`contact_${item.user_id}`} {...item} {...props} />)}
      </List>
    )
  }
}

export default Contacts

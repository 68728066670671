import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'

import Boxes from 'components/Boxes'

import Diary from 'components/Diary'
import Photos from 'components/Photos'
import Messages from 'components/Messages'
import Contacts from 'components/Contacts'
import Interests from 'components/Interests'
import Services from 'components/Services'
import ContentRoutes from 'components/Content'

const MainRoutesContainer = styled.div`
  height: 70vh;
  padding: 0;
`

@inject('pageStore')
@observer
class MainRoutes extends Component {

  componentDidMount() {
    const { match, pageStore, history } = this.props
    document.withNavigation.forceRefresh()
    pageStore.goToPage(match.params.page)
  }

  componentDidUpdate(prevProps) {
    const { match, pageStore, history } = this.props
    const currentPage = match.params.page
    const prevPage = prevProps.match.params.page
    if (currentPage !== prevPage) {
      pageStore.goToPage(match.params.page)
      document.withNavigation.forceRefresh()
    }
  }

  render() {
    return (
      <MainRoutesContainer>
        <Switch>
          <Route path={`/main/interests`} component={Interests} />
          <Route path={`/main/contacts`} component={Contacts} />
          <Route path={`/main/messages`} component={Messages} />
          <Route path={`/main/photos`} component={Photos} />
          <Route path={`/main/diary`} component={Diary} />
          <Route path={`/main/services`} component={Services} />
          <Route path={`/main/:page/content`} component={ContentRoutes} />
          <Route path={`/main/suggestions`} component={ContentRoutes} />
          <Route path={`/main/suggestions-activities`} component={ContentRoutes} />
          <Route path={`/main/suggestions-content`} component={ContentRoutes} />
          <Route path={`/main/:page`} component={Boxes} />
        </Switch>
      </MainRoutesContainer>
    )
  }
}

export default withRouter(MainRoutes)

import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { observable } from 'mobx'
import { observer, inject } from 'mobx-react'

import { DiaryDay } from 'components/Diary'

import { theme, List, ListItem } from 'UI'

const DiaryContainer = styled(List)`
`

@inject('pageStore', 'eventsStore')
@observer
class Diary extends Component {

  componentDidMount() {
    const { pageStore, eventsStore } = this.props
    pageStore.setTitle('Diary')
    eventsStore.getEvents()
  }

  componentWillUnmount() {
    const { pageStore } = this.props
    pageStore.setTitle(undefined)
  }

  render() {
    const { eventsStore } = this.props
    return (
      <DiaryContainer>
        {eventsStore.weekDiveded.map(day => <DiaryDay key={`day_${day[0]}`} day={day} props={this.props} />)}
      </DiaryContainer>
    )
  }
}

export default Diary

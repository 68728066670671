import { useState } from 'react'
import { computed, toJS } from 'mobx'

export function getFrom(store) {
  return {
    where: function (key, value) {
      const index = store.findIndex(item => item[key] === value)
      return toJS(store[index])
    }
  }
}

export function updateIn(store) {
  let index
  return {
    where: function (key, value) {
      index = store.findIndex(item => item[key] === value)
      return this
    },
    to: function (key, value) {
      store[index][key] = value
    }
  }
}

export function deleteFrom(store) {
  return {
    where: function (key, value) {
      const index = store.findIndex(item => item[key] === value)
      store.splice(index, 1)
    }
  }
}

const stores = []

export const isAnyStoreLoading = () => {
  const isLoading = stores.find(item => item.isLoading && !item.length)
  return !!isLoading
}

export const isStoreEmpty = currentLocation => {
  const store = stores.find(item => RegExp(item.path).test(currentLocation))
  if (store === undefined) return stores.find(item => item.path === '/main/page')?.isEmpty
  return store?.isEmpty
}

export const getEmptyMessage = currentLocation => {
  const store = stores.find(item => RegExp(item.path).test(currentLocation))
  if (store === undefined) return 'Nothing to show'
  return store.emptyMessage
}

export const getStoreFor = currentLocation => {
  const item = stores.find(item => RegExp(item.path).test(currentLocation))
  if (!item) {
    return {
      isLoading: false,
      isEmpty: true,
      emptyMessage: "Nothing to show"
    }
  }
  return item
}

export function withStatusFor(store, options = {}) {
  const { withMainField = '_items', onPath, emptyMessage = 'Nothing to show' } = options

  if (!store[withMainField]) return store

  const path = onPath || `/main/${store.constructor.name.replace('Store', '').toLowerCase()}`
  stores.push(store)

  const properties = ['name', 'path', 'length', 'isLoading', 'isLoaded', 'isEmpty', 'emptyMessage']
  const newProps = {
    name: { value: store.constructor.name },
    path: { value: path },
    length: { get: () => computed(() => store['link']?.length).get() },
    isLoading: { get: () => computed(() => store[withMainField]?.state === 'pending').get() },
    isLoaded: { get: () => computed(() => store[withMainField]?.state === 'fulfilled').get() },
    isEmpty: { get: () => computed(() => (store.isLoaded && !store.length)).get() },
    emptyMessage: { value: emptyMessage }
  }

  properties.forEach(prop => {
    if (!store.hasOwnProperty(prop)) {
      Object.defineProperty(store, prop, newProps[prop])
    }
  })
  return store
}

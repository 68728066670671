import React, { Component } from 'react'
import { observer, inject } from 'mobx-react/index'

import { Switch, Route } from 'react-router-dom'

import { List } from 'UI'
import ContentListItem from './ContentListItem'
import ContentPage from './YoutubeContent'

@inject('pageStore', 'contentStore')
@observer
class ContentList extends Component {

  componentDidMount() {
    const { pageStore } = this.props
    pageStore.updateCurrentPageTitle()
  }

  render() {
    const { contentStore } = this.props
    return (
      <List>
        {contentStore.content.map((item, index) => <ContentListItem key={`content_${item.id}`} {...item} first focusOrder={index} name={`contentItem_${item.id}`} />)}
      </List>
    )
  }
}

export default ContentList

import React, { isValidElement, cloneElement, useLayoutEffect, useRef, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { focusable } from 'components/withNavigation'

import { theme, Block } from 'UI'

const selected = css`
  box-shadow: ${theme.boxShadowSelected};
  transform: scale(1.05);
  z-index: 1;
  ${p => p.styles?.selected || ''}
`

const notSelected = css`
  box-shadow: ${theme.boxShadow};
  opacity: 0.6;
  transform: scale(0.9)};
  ${p => p.styles?.notSelected || ''}
`

const blank = css`
  transition: ${theme.transition};
  background: none;
  border-radius: 0;
  position: relative;
  box-shadow: none;
`

const StyledListItem = styled(Block)`
  margin-bottom: 5vh;
  padding: ${theme.padding};
  transition: ${theme.css.transition};
  ${p => p.shouldRender && (p.selected ? selected : notSelected)};
  ${p => p.transparent && 'background: transparent; box-shadow: none'};
  ${p => !p.shouldRender && blank};
`

const ListItemContainer = styled.div`
  display: flex;
  //padding: ${theme.padding};
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
`

const EmptyItem = styled.div`
  height: calc(${p => `${p.height}px`} - ${theme.interval(4)});
`

const withKey = (item, index) => ({ ...item, key: `listItemChild_${index}` })

function ListItem(
  { 
    selected, 
    forwardedRef,
    _focus_id, 
    transparent = false, 
    component, 
    styles = {}, 
    children = [], 
    onPress, 
    listIndex, 
    setCurrentIndex, 
    setCallback,
    shouldRender,
    className,
    focusOrder,
    ...rest
  }) {
  
  const listItem = useRef()
  const [ height, setHeight ] = useState(0)

  useLayoutEffect(() => {
    if (onPress) setCallback(onPress)
    if (selected) setCurrentIndex(listIndex)
    setHeight(listItem.current.offsetHeight)
  }, [shouldRender, selected])

  return (
    <StyledListItem selected={selected} ref={forwardedRef} transparent={transparent} styles={styles} shouldRender={shouldRender} {...rest} className={className}>
      <ListItemContainer ref={listItem} shouldRender={shouldRender} >
        {shouldRender
          ? (children.length !== undefined
              ? children.map(withKey).map(child => isValidElement(child) && cloneElement(child, { selected }))
              : cloneElement(children, { selected }))
          : <EmptyItem height={height} />
        }
      </ListItemContainer>
    </StyledListItem>
  )
}

export default focusable(ListItem, { isList: true, first: true })

import { observable, action, computed, toJS, when } from 'mobx'
import { normalize, schema } from 'normalizr'

import { getMenus } from 'utils/mockData.js'
import { api } from 'utils/fetcher'
import { withStatusFor } from 'utils/storeHelpers'

const page = new schema.Entity('pages')
const children = new schema.Array(page)
page.define({ children })
const withPagesSchema = { children: [page] }

class PageStore {

  constructor() {
    when(
      () => this.isLoaded,
      isLoaded => { this.goToPage(this.missingPage); this.missingPage = null }
    )
  }

  @observable mainId = undefined
  @observable _pageData = {}
  @computed get pageData() { return toJS(this._pageData) }
  @computed get link() { return this._pageData?.children || [] }

  @observable _currentPage = undefined
  @computed get currentPage() { return this._currentPage || {} }
  @computed get page() { return toJS(this._currentPage) || {} }

  @observable _title = undefined
  @computed get title() { return this._title || this.page.title || 'Main' }
  @action setTitle(newTitle) { if (newTitle) this._title = newTitle }

  @observable isLoading = false
  @observable isLoaded = false

  @computed get length() { return Object.keys(this.pageData).length }

  @observable defaultColor = 'rgba(80, 47, 95, 0.96)'

  @observable _color = null
  @computed get color() { return this._color }

  @observable missingPage = null

  @action
  getPage = async () => {
    this.isLoading = true
    const response = await api.get().from.page.all()
    if (response?.id === undefined) { this.isLoading = false; return false }
    const setColor = parent => {
      parent.children.forEach(child => {
        child.color = parent.color
        setColor(child)
      })
    }

    response.children?.forEach(child => setColor(child))

    const normalized = normalize(response, withPagesSchema)
    const data = {
      '1': normalized.result,
      [normalized.result.id]: normalized.result,
      ...normalized.entities.pages
    }
    
    this.mainId = normalized.result.id
    this._pageData = data
    this.preloadImages(data)
    this.isLoading = false
    this.isLoaded = true
  }

  @action updateCurrentPageTitle =  () => {
    if(this._currentPage){
      this.setTitle(this._currentPage.title)
    }
  }

  preloadImages = data => {
    const head = document.head
    for (let id in data) {
      if (data[id].photo) {
        let link = document.createElement('link')
        link.href = data[id].photo || ''
        link.rel = 'preload'
        link.as = 'image'
        head.appendChild(link)
      }
    }
  }

  @action
  goToPage = page => {
    const newPage = this.pageData[page] || this.findPageByHref(page) || {}
    if (!newPage.id) this.missingPage = page
    this._currentPage = newPage
    this.setTitle(newPage.title)
    this._color = newPage.color || (newPage.href === 'content-list' ? this.color : this.defaultColor)
  }

  findPageByHref = href => {
    return Object.values(toJS(this.pageData)).find(item => item.href === href)
  }

  @computed get children() {
    return this.page.children?.map(id => this.pageData[id] || false)?.filter(item => item).sort((a,b) => a.order - b.order) || []
  }

  @computed get type() { return this.page.type || undefined }

  @observable menuItems = getMenus()
  @computed get menus() { return toJS(this.menuItems) }

  refreshStore = () => this.getPage()

}

export const pageStore = new PageStore()
export default withStatusFor(pageStore, { withMainField: '_pageData', onPath: '\/main\/[0-9]+$' })

export const jsBridgeFunctions = {
    setStreamVolume: 'setStreamVolume',
    launchZoomMeeting: 'launchZoomMeeting',
    testCall: 'testCall',
};

export const jsBridgeFunctionsEnums = {
    [jsBridgeFunctions.setStreamVolume]: {
        voice: 'voice',
        music: 'music',
    },
    [jsBridgeFunctions.launchZoomMeeting]: {
        meeting_id: 'meeting_id',
        meeting_encrypted_password: 'meeting_encrypted_password',
        type: 'type'
    },
    [jsBridgeFunctions.testCall]: {
        
    }
}
import React, { Component, createContext } from 'react'
import uuid from 'uuid/v4'
import memoizee from 'memoizee'

import { getDisplayName } from './utils'

const AreaContext = createContext()
const getContextValue = (areaId, onlyArea, oneDimension) => ({ areaId, onlyArea, oneDimension})
const memoizeed = memoizee(getContextValue, { primitive: true })

function area(WrappedComponent, passedOptions = {}) {

  const options = {
    onlyArea: false,
    oneDimension: false,
    ...passedOptions
  }

  class Area extends Component {

    constructor(props) {
      super(props)
      this._area_id = uuid()
    }

    _area_id = undefined

    render() {
      const { ...rest } = this.props
      return (
        <AreaContext.Provider value={memoizeed(this._area_id, options.onlyArea, options.oneDimension)}>
          <WrappedComponent {...rest} />
        </AreaContext.Provider>
      )
    }
  }

  Area.displayName = `Area(${getDisplayName(WrappedComponent)})`
  return Area
}

area.context = AreaContext

export default area

import { snakeCase } from 'change-case'
import { newMock } from './mockData'

interface Item {
  title: string
  href: string
  color?: string
  order?: number
  image: string
}

interface Page {
  title: string
  href: string
  color?: string
  type: 'boxes' | 'content-list' | 'unique'
  order?: number
  items?: Array<Item>
  hasSuggestions?: boolean
  hasSaved?: boolean
}

interface Data {
  [index: number]: Page
}

const convertItems = item => ({
  title: item.title,
  href: item.href || snakeCase(item.title),
  ... ( item.color ? { color: item.color } : {}),
  order: 0,
  image: item.photo
})

const data = newMock
  .map( item => {
    if (item.href === 'content') return false
    const obj = {
      title: item.title,
      href: item.href || snakeCase(item.title),
      ...(item.color ? { color: item.color } : {}),
      type: item.type === 'content' ? 'content-list' : 'boxes',
      ...(item.order !== undefined ? { order: item.order } : {}),
      ...(item.items?.length > 0 ? { items: item.items.map(convertItems) } : {}),
      ...(item.hasSuggestions? { hasSuggestions: true } : {}),
      ...(item.hasSaved ? { hasSaved: true } : {})
    }
    return obj
  })
  .filter(item => item)

import React from 'react'
import styled from 'styled-components'
import { Text, theme } from 'UI'

const ThumbContainer = styled.div`
  position: relative;
  width: calc(44.5vw + 40px);
  height: 25vw;
  border-radius: ${theme.borderRadius};
  margin: 0 auto;
  overflow: hidden;
  padding: 0 20px;
  box-sizing: border-box;
  flex: 1;
`

const ThumbHeader = styled(Text.H3)`
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  color: white;
  padding: ${theme.padding};
`

const ThumpFooter = styled.div`
  z-index: 10;
  position: absolute;
  right: ${theme.interval(2)};
  bottom: ${theme.interval(3)};
  box-sizing: border-box;
  padding: ${theme.padding};
  text-align: right;
`

const ThumbImg = styled.img`
  width: 44.5vw;
  height: 25vw;
  border-radius: ${theme.borderRadius};
  margin: 0 auto;
  box-shadow: ${theme.boxShadow};
`

const ThumbCover = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 20px;
  width: 44.5vw;
  height: 25vw;
  background: rgba(0,0,0,0.35);
  border-radius: ${theme.borderRadius};
`

function Thumb({ src, header, footer = null, ...rest }) {
  return (
    <ThumbContainer {...rest}>
      <ThumbCover />
      <ThumbHeader>{header}</ThumbHeader>
      <ThumbImg src={src} />
      <ThumpFooter>{footer}</ThumpFooter>
    </ThumbContainer>
  )
}

export default Thumb

import React from 'react'
import styled from 'styled-components'

import emptyPage from 'assets/images/emptyPage.png'

import Img from 'UI/Img/index'
import theme from 'UI/theme'
import Text from '../../UI/Text/index'

const Empty = styled.div`
  color: white;
  text-align: center;
  position:fixed;
  top: 50%;
  left: 50%;
`

const EmptyImg = styled(Img)`
  border-radius: ${theme.borderRadius};
  box-shadow: ${theme.boxShadow};
  width: 50%;
`

const EmptyPage = ({ message = 'Nothing to show', ...rest}) => (
  <Empty {...rest}>
    {/*<EmptyImg src={emptyPage} alt="Empty page" />*/}
    <Text.H2>{message}</Text.H2>
  </Empty>
)

export default EmptyPage

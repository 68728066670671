import React, { Component, createRef } from "react";
import styled from "styled-components";
import { observable, action } from "mobx";
import { observer, inject } from "mobx-react";
import { format, parseISO } from "date-fns";
import { focusable } from "components/withNavigation";

import { ContentDetails } from "components/Content";
import Thumb from "./Thumb";

import jsbridge from "utils/jsbridge";
import config from "utils/config";

import {
  theme,
  Img,
  Text,
  Button,
  ButtonsRow,
  Modal,
  ScrollableText,
} from "UI";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70vh;
`;

const Buttons = styled(ButtonsRow)`
  margin-top: ${theme.interval(1.5)};
  justify-content: space-evenly;
`;

const YoutubeModal = styled.div`
  min-width: 55vw;
  height: 100%;
  padding: ${theme.interval(2)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const YouTubeModalTitle = styled(Text.H2)`
  margin-bottom: ${theme.interval()};
`;

const ttt =
  "You have to preach, and study man by your growing.Confucius says: in earth PLURALdie purely extend.You have to fail, and visualize faith by your balancing." +
  "The great unknown is full of dimension.A sincere form of music is the heaven.To some, an ego is a manifestation for absorbing.The mysterious justice of happiness is to respect with result." +
  "The monkey loves.Always theosophically facilitate the atomic individual.The teacher has heaven, but not everyone emerges it.Our holographic life for love is to yearn others oddly." +
  "Never meet the aspect, for you cannot gain it.The yogi has faith, but not everyone believes it.The meditation of your stigmas will ease purely when you gain that manifestation is the " +
  "guru.You have to convert, and need emptiness by your disturbing." +
  "If you fly or do with a holy grace, happiness shapes you.To some, a yogi is a grace for capturing.Our great light for heaven is to praise others substantially.Who can facilitate the " +
  "dogma and milk of a thing if he has the embittered joy of the moon?" +
  "Always earthly believe the secret yogi.The surrender of discovering karmas is abstruse.Not wonderland or chaos, discover the satori.One holy zen i give you: shape each other." +
  "Volume desires when you realize with conclusion.Who can synthesise the grace and reincarnation of a source if he has the mediocre joy of the sinner?All shining gurus hear each other, " +
  "only sincere sources have a silence.The reincarnation is a parallel ego." +
  "In the outer world all masters hear conclusion.All further spirits synthesise each other, only apostolic yogis have a fear.Art is the only surrender, the only guarantee of tantra." +
  "Music, art and a shining kingdom.Volume believes when you desire with blessing.Individuals, selfs, and abstruse believers will always protect them." +
  "All embittered lotus remember each other, only occult seekers have an acceptance.Purpose, density and a magical country of mind.The moonlight of desiring sinners is unveiled.";

@inject("pageStore", "contentStore", "sharedStore")
@observer
class YoutubeContent extends Component {
  @observable isPlaying = false;
  @observable isFinished = false;

  @observable _timePlayed = null;
  @observable saved = undefined;

  @observable isModalOpen = false;

  get timePlayed() {
    return this._timePlayed;
  }
  set timePlayed(newVal) {
    //const { content_youtube_id } = this.props;
    const { contentStore, match } = this.props;
    const { content_youtube_id } = contentStore.contentFor(
      match.params.contentId
    );
    this._timePlayed = newVal;
    localStorage.setItem(
      `youtube_${content_youtube_id}`,
      JSON.stringify({ last: newVal, isFinished: false })
    );
  }

  handleStopRead = () => {
    this.props.sharedStore.stopReadText();
  };

  componentDidMount() {
    const { pageStore, contentStore, match } = this.props;
    contentStore.setCurrentFor(match.params.contentId);
    const { content_header, content_youtube_id } = contentStore.contentFor(
      match.params.contentId
    );
    this.getTimePlayed(content_youtube_id);

    contentStore.setYoutubeCallback(this.setTimePlayed);
  }

  @action
  componentDidUpdate() {
    const { contentStore, match, pageStore } = this.props;
    const { saved, content_header, ...rest } = contentStore.contentFor(
      match.params.contentId
    );
    if (this.saved === undefined) {
      this.saved = saved;
    }
  }

  @action
  getTimePlayed = (content_youtube_id) => {
    const { contentStore } = this.props;
    const fromLocalStorage = JSON.parse(
      localStorage.getItem(`youtube_${content_youtube_id}`)
    ) || { last: 0, isFinished: false };

    this.timePlayed = parseInt(fromLocalStorage.last || 0, 10);
    this.isFinished = fromLocalStorage.isFinished || false;
  };

  @action
  setTimePlayed = (contentYoutubeId, timePlayed = {}) => {
    const { last = 0, isFinished = false } = timePlayed;
    const time = typeof last === "number" ? last : parseInt(last, 10);
    this.timePlayed = isNaN(time) ? 0 : time;
    this.isFinished = isFinished;
    localStorage.setItem(
      `youtube_${contentYoutubeId}`,
      JSON.stringify(timePlayed)
    );
  };

  componentWillUnmount() {
    const { pageStore } = this.props;
    pageStore.setTitle(undefined);
    this.handleStopRead();
  }

  @action
  play = (milisec) => {
    const { contentStore, match } = this.props;
    const { content_youtube_id } = contentStore.contentFor(
      match.params.contentId
    );

    const timePlayed = {
      last: 0,
      isFinished: false,
    };
    contentStore.setYouTubePlayData(content_youtube_id, timePlayed);
    localStorage.setItem(`played_youtube_${content_youtube_id}`, "true");

    this.getTimePlayed(content_youtube_id);
    jsbridge.call(
      "startYoutubeVideo",
      content_youtube_id,
      milisec || this.timePlayed
    );
    this.isPlaying = true;
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  @action
  pause = () => {
    const { contentStore, match } = this.props;
    const { content_youtube_id, content_header, content_markup } =
      contentStore.contentFor(match.params.contentId);
    jsbridge.call("stopYoutubeVideo", content_youtube_id);
    this.isPlaying = false;
  };

  handleSaved = () => {
    if (this.saved) {
      this.removeContent();
    } else {
      this.saveContent();
    }
  };

  @action
  saveContent = () => {
    const { contentStore, match } = this.props;
    const { content_id } = contentStore.contentFor(match.params.contentId);
    contentStore.saveContent(content_id);
    this.saved = true;
  };

  @action
  removeContent = () => {
    const { contentStore, match } = this.props;
    const { content_id } = contentStore.contentFor(match.params.contentId);
    contentStore.removeContent(content_id);
    this.saved = false;
  };
  get playText() {
    if (this.isFinished) return "Replay";

    const { contentStore, match } = this.props;
    const { content_youtube_id } = contentStore.contentFor(
      match.params.contentId
    );
    const fromLocalStorage = JSON.parse(
      localStorage.getItem(`played_youtube_${content_youtube_id}`)
    );
    if (fromLocalStorage) return "Continue";

    if (this.timePlayed) return "Continue";

    return "Play";
  }

  handleReadText = () =>
    this.props.sharedStore.isNowReading
      ? this.handleStopRead()
      : this.handleStartRead();

  handleStartRead = () => {
    const { contentStore, match, sharedStore } = this.props;
    const {
      content_header,
      content_address,
      content_start_date,
      content_price,
      content_markup,
    } = contentStore.contentFor(match.params.contentId);

    const desc = content_markup
      ? content_markup.replace(/<\/?[^>]+(>|$)/g, "")
      : "";
    const starts = content_start_date
      ? format(parseISO(content_start_date), config.dateTimeFormat)
      : "";

    const text = content_header
      ? content_header +
        "." +
        " " +
        content_address +
        "." +
        " " +
        starts +
        "." +
        " " +
        content_price +
        "." +
        " " +
        desc
      : PLACEHOLDER_TEXT;
    sharedStore.readText(text);
  };

  handlePlay = () => {
    this.play();
    this.handleStopRead();
  };

  @action
  openModal = () => (this.isModalOpen = true);

  @action
  closeModal = () => (this.isModalOpen = false);

  render() {
    const { contentStore, match, sharedStore } = this.props;
    const { content_youtube_id, content_header, content_markup, ...rest } =
      contentStore.contentFor(match.params.contentId);
    const text =
      content_markup?.replace(
        /(<\/?[^>]+(>|$))|(&nbsp;)|(&amp;)|(null)/g,
        " "
      ) || "";

    return (
      <ContentDetails>
        <ContentContainer>
          <Thumb
            src={`https://i.ytimg.com/vi/${content_youtube_id}/mqdefault.jpg`}
            header={content_header}
          />
          <Buttons>
            <Button
              text={this.playText}
              icon="play"
              first
              onPress={this.handlePlay}
              focusOrder={1}
            />
            <Button text="Details" icon="file-alt" onPress={this.openModal} />
            <Button
              text={this.saved ? "Remove" : "Save"}
              icon={this.saved ? "times" : "plus"}
              onPress={this.handleSaved}
            />
          </Buttons>
        </ContentContainer>
        {this.isModalOpen && (
          <Modal onBack={this.closeModal}>
            <YoutubeModal>
              <YouTubeModalTitle bold>{content_header}</YouTubeModalTitle>
              <ScrollableText
                isNowReading={sharedStore.isNowReading}
                onReadPress={this.handleReadText}
                onClosePress={this.closeModal}
              >
                <Text.H2 thin>{text}</Text.H2>
              </ScrollableText>
            </YoutubeModal>
          </Modal>
        )}
      </ContentDetails>
    );
  }
}

export default YoutubeContent;

import { observable, action } from 'mobx'

import jsbridge from 'utils/jsbridge'

class SharedStore {
  
  @observable isNowReading = false
  @observable allTextBeenRead = false

  @action setIsNowReadingState = (isNowReading, allTextBeenRead) => {
    this.isNowReading  = isNowReading
    this.allTextBeenRead = allTextBeenRead
  } 

  readText = (text) => {
    if (this.isNowReading) this.stopReadText()
    
    const trimmedText = text.replace(/(null)|(&nbsp;)|(&amp;)|(<\/?[^>]+(>|$))/g, '')
    console.log(`--- SharedStore -> readText -> , ${trimmedText}`)
    jsbridge.call('readText', trimmedText)
    this.setIsNowReadingState(true,false)  
  }

  stopReadText = () => {
    jsbridge.call('stopTextReader')
    this.setIsNowReadingState(false,false)  
  }
}

export const sharedStore = new SharedStore()
export default sharedStore

import React, { Component } from "react";
import styled, { css } from "styled-components";
import { observable, action } from "mobx";
import { observer, inject } from "mobx-react";
import tinycolor from "tinycolor2";

import InterestsItem from "./InterestsItem";

import { theme, Button, Text, Icon, Modal, ButtonsRow } from "UI";

const Container = styled.div`
  display: flex;
`;

const Game = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 40vw;
`;

const Answers = styled.div`
  position: absolute;
  margin-right: ${theme.interval(2)};
  right: 0;
  width: 24vw;
  transition: ${theme.transition};
  flex-shrink: 0;
`;

const AnswersItem = styled(Button)`
  margin-bottom: 3vh;
  height: auto;
  padding: ${theme.interval(2)};
`;

const selectedForItem = css`
  color: white;
  background: ${theme.colors.redRgba(0.8)};
`;

const selectedForButton = css`
  background: rgba(0, 0, 0, 0.15);
  color: white;
  transform: scale(1);
  box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.2);
`;

const stylesForItem = {
  selected: selectedForItem,
};

const stylesForButton = {
  selected: selectedForButton,
};

const SavedInterests = styled(Text.H3)`
  color: white;
  height: 10vh;
`;

const Wrap = styled.div`
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(p) => (p.started ? 1 : 0.6)};
  transform: scale(${(p) => (p.started ? 1 : 0.9)});
`;

const Title = styled(Text.H3)`
  color: white;
  margin-top: ${theme.interval(2)};
`;

const Thumbs = styled(Button)`
  width: 4vw;
  z-index: 1;
  color: white;
  position: absolute;
  top: 14vh;
  background: ${(p) =>
    p.like ? `${theme.colors.greenRGBA(0.8)}` : `${theme.colors.redRgba(0.8)}`};
  ${(p) => (p.like ? `right: -10vw` : `left: -10vw`)};
  display: flex;
  justify-content: space-between;
  transition: ${theme.css.transition};
  transform: ${(p) => (p.animate ? "scale(1.2)" : "scale(1)")};
`;

const Empty = styled.div`
  color: white;
  text-align: center;
  height: 50vh;
  width: 36vh;
  display: flex;
  align-items: center;
`;

const Heart = styled(Icon)`
  margin-right: ${theme.interval()};
`;

const InterestsModal = styled(Modal)`
  padding: ${theme.padding};
  align-items: center;
  justify-content: space-between;
  background: ${(props) =>
    props.color
      ? `linear-gradient(to right bottom, ${tinycolor(props.color)
          .lighten(40)
          .toHexString()}, ${tinycolor(props.color).darken(10).toHexString()})`
      : ""};
`;
const InterestsModalText = styled(Text.H3)`
  color: white;
`;
const Texts = styled.div``;

const MAX_BATCH = 7;

@inject("interestStore", "pageStore")
@observer
class Interests extends Component {
  @observable isStarted = false;
  @observable current = null;
  @observable animatingFor = undefined;
  @observable countActions = 0;
  @observable isButtonSelected = false;
  @observable isModalOpen = false;

  @action
  componentDidMount() {
    const { interestStore, pageStore } = this.props;
    const { setCallback } = document.withNavigation.keyCallback;
    interestStore.getInterests();
    setCallback(this.handleKeys);

    pageStore.setTitle("What are you interested in?");
  }

  @action
  componentDidUpdate(prevProps) {
    const { interestStore } = this.props;
    if (!interestStore.currentItem.title) interestStore.getRandom();
    if (interestStore.isEmpty) this.isStarted = false;
  }

  @action
  handleToggleGame = () => {
    const { interestStore } = this.props;
    if (!this.isStarted && (interestStore.isEmpty || interestStore.isLoading))
      return false;

    this.isStarted = !this.isStarted;
    const { shouldUse } = document.withNavigation.keyCallback;
    shouldUse(this.isStarted);
    if (!this.isStarted) this.isButtonSelected = false;
  };

  @action
  handleKeys = (keyCode) => {
    if (keyCode === 38) this.isButtonSelected = true;
    if (keyCode === 39) this.handleLike("like");
    if (keyCode === 40) this.isButtonSelected = false;
    if (keyCode === 37) this.handleLike("dislike");
  };

  @action
  handleLike = (likeOrDislike) => {
    const { interestStore } = this.props;
    if (interestStore.currentItem) {
      this.countActions += 1;

      interestStore.makeLike(likeOrDislike);
      this.animatingFor = likeOrDislike;

      setTimeout(() => {
        this.animatingFor = undefined;
        interestStore.getRandom();
      }, 300);

      if (this.countActions === MAX_BATCH || interestStore.isEmpty) {
        this.handleModalToggle();
        this.handleToggleGame();
      }
    }
  };

  @action
  handleRestart = () => {
    const { interestStore } = this.props;
    this.countActions = 0;
    interestStore.restartGame();
  };

  handleSkip = () => {
    const { history } = this.props;
    // this.handleModalToggle()
    history.push("/main/1");
  };

  @action
  handleModalToggle = () => (this.isModalOpen = !this.isModalOpen);

  render() {
    const { interestStore, pageStore } = this.props;

    return (
      <Container>
        <Game>
          {!interestStore.isEmpty && (
            <Button
              text={this.isStarted ? "End" : "Start"}
              icon="ok"
              onPress={this.handleToggleGame}
              styles={
                this.isStarted && !this.isButtonSelected ? stylesForButton : {}
              }
              name="Start"
              disabled={this.isModalOpen}
              first
              focusOrder={1}
            />
          )}
          {interestStore.isEmpty && (
            <Button text="Come back later" onPress={this.handleSkip} />
          )}
          <Wrap started={this.isStarted}>
            {interestStore.isEmpty && (
              <Empty>
                <Text.H2>There are no more cards to show</Text.H2>
              </Empty>
            )}
            {!interestStore.isEmpty && !interestStore.isLoading && (
              <InterestsItem
                animatingFor={this.animatingFor}
                like={
                  <Thumbs
                    text="No"
                    icon="thumbs-down"
                    dislike
                    animate={this.animatingFor === "dislike"}
                    disabled={true}
                  >
                    <Icon icon="caret-square-left" />
                    <Icon icon="thumbs-down" />
                  </Thumbs>
                }
                dislike={
                  <Thumbs
                    text="Yes"
                    icon="thumbs-up"
                    like
                    animate={this.animatingFor === "like"}
                    disabled={true}
                  >
                    <Icon icon="thumbs-up" />
                    <Icon icon="caret-square-right" />
                  </Thumbs>
                }
              />
            )}
          </Wrap>
        </Game>
        <Answers>
          <SavedInterests bold>
            <Heart icon="heart" />
            Your saved interests
          </SavedInterests>
          {interestStore.saved.isEmpty && (
            <AnswersItem disabled>
              You have not picked any interests yet
            </AnswersItem>
          )}
          {!interestStore.saved.isEmpty && (
            <AnswersItem
              text="Restart"
              icon="undo"
              onPress={this.handleRestart}
            />
          )}
          {interestStore.saved.map((item, index) => (
            <AnswersItem
              key={`saved_${item.title}_${index}`}
              text={item.title}
              styles={stylesForItem}
              focusOrder={2}
            />
          ))}
        </Answers>
        {this.isModalOpen && (
          <InterestsModal color={pageStore.color}>
            <Texts>
              <InterestsModalText bold>
                There are more cards to draw
              </InterestsModalText>
              <InterestsModalText>
                You can keep playing now or skip and come back later
              </InterestsModalText>
            </Texts>
            <ButtonsRow>
              <Button text="Continue" onPress={this.handleModalToggle} />
              <Button
                text="Skip"
                first
                focusOrder={0}
                onPress={this.handleSkip}
                name="skip"
              />
            </ButtonsRow>
          </InterestsModal>
        )}
      </Container>
    );
  }
}

export default Interests;

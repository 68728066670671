import React, { useState, useEffect, forwardRef } from 'react'
import styled from 'styled-components'

import empty from 'assets/images/empty.jpg'

import { theme, Spinner } from 'UI'

const ImageContainer = styled.div`
  display: inline;
`

const ImgComponent = styled.img`
  ${p => !p.loaded && 'height: 0'};
`

const Loading = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${empty}) center center;
  background-size: cover;
`

const Img = ({ src=empty, alt='image', width, height, handleLoad, forwardedRef, ...props }) => {
  const [ isLoaded, setLoaded ] = useState(false)
  const imgLoaded = () => {
    if (typeof handleLoad === 'function') handleLoad()
    setLoaded(true)
  }
  return (
    <ImageContainer {...props}>
      {!isLoaded && <Loading><Spinner /></Loading>}
      <ImgComponent src={src} alt={alt} onLoad={imgLoaded} loaded={isLoaded} height={isLoaded ? height : 0} width={isLoaded ? width : 0} {...props} ref={forwardedRef} />
    </ImageContainer>
  )
}

export default forwardRef((props, ref) => <Img {...props} forwardedRef={ref} />)

import React, { Component } from 'react'
import styled from 'styled-components'
import { observable, autorun } from 'mobx'
import { observer, inject } from 'mobx-react'
import tinycolor from 'tinycolor2'
import { Route } from 'react-router-dom'

import { router } from 'Routes'
import { StatusHandler, Header, MainRoutes } from 'components/Layout'
import SideMenu from 'components/SideMenu'

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  //background: ${props => props.color ? `linear-gradient(to right bottom, ${tinycolor(props.color).lighten(40).toHexString()}, ${tinycolor(props.color).darken(10).toHexString()})` : ''};
  display: flex;
`

const StyledBody = styled.div`
  padding-top: 2vh;
  margin-top: 2vh;
`

const Background = styled.div`
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;
  background: ${props => props.color ? `linear-gradient(to right bottom, ${tinycolor(props.color).lighten(40).toHexString()}, ${tinycolor(props.color).darken(10).toHexString()})` : ''};
`

@inject('pageStore')
@observer
class Layout extends Component {

  componentDidMount() {
    const { pageStore } = this.props
    pageStore.getPage()
  }

  render() {
    const { pageStore, location } = this.props

    return (
      <Container color={pageStore.color}>
        <Background color={pageStore.color} />
        <SideMenu />
        <Route path={`/main/:page`}>
          <StatusHandler>
            <Header />
            <StyledBody>
              <MainRoutes color={pageStore.color} />
            </StyledBody>
          </StatusHandler>
        </Route>
      </Container>
    )
  }
}

export default Layout

import React, { Component, createRef } from 'react'
import uuid from 'uuid/v4'

import moveSoundPath from './move.mp3'
import clickSoundPath from './click.mp3'

import { getDisplayName, getBoundingClientRect, throttled, debounced, isInTriangle, getNodeTranslateY, logged, drawItemCenter } from './utils'

const styles = { overflow: 'hidden', outline: 'none' }

function withNavigation(WrappedComponent, passedOptions = {}) {

  const options = {
    globalName: 'withNavigation', // Not used yet coz dont know yet how to pass this name to 'focusable'
    findFirstDelay: 500,
    keyHandlingDelay: 400,
    keys: {
      up: [38, 'arrowUp'],
      right: [39, 'arrowRight'],
      down: [40, 'arrowDown'],
      left: [37, 'arrowLeft'],
      enter: [13, 'Enter'],
      back: [27, 'Escape']
    },
    sounds: {
      move: new Audio(clickSoundPath),
      click: new Audio(moveSoundPath)
    },
    debug: false,
    ...passedOptions
  }

  class WithNavigation extends Component {
    constructor(props) {
      super(props)
      if (!document.withNavigation) {
        document.withNavigation = {
          setFocus: this.setFocus,
          getSelectedFocusable: this.getSelectedFocusable,
          addFocusable: this.addFocusable,
          removeFocusable: this.removeFocusable,
          setDisabled: this.setDisabled,
          checkFocusable: this.checkFocusable,
          forceRefresh: this.forceRefresh,
          setBackCallback: this.setBackAction,
          pressKey: this.pressKey,
          keyCallback: this._keyCallback,
          setShouldStoreFocusableTo: this.setShouldStoreFocusableTo,
          setNextFocusOrder: this.setNextFocusOrder,
          nextWith: this.nextWith
        }
      }
    }
    state = {
      selectedFocusable: null
    }

    navigationEl = createRef()
    _focus_id = uuid()
    stateQueue = []
    items = []
    prevBackAction = null
    backAction = null
    isDisabled = false
    keyCallback = null
    isKeyCallbackUsed = false
    setUseKeyCallback = bool => this.isKeyCallbackUsed = bool
    get _keyCallback() {
      const that = this
      return {
        isUsed: () => that.isKeyCallbackUsed,
        shouldUse: bool => that.setUseKeyCallback(bool),
        setCallback: fn => that.keyCallback = fn,
        shared: that
      }
    }

    storedFocusable = null
    isShouldStoreFocusable = false
    nextFocusOrder = undefined
    setNextFocusOrder = focusOrder => this.nextFocusOrder = focusOrder

    next = null
    nextWith = key => {
      const that = this
      return {
        equals: value => {
          that.next = {
            key: key,
            value: value
          }
        }
      }
    }

    componentDidMount() {
      this.setFocus()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (!this.state.selectedFocusable && !this.isDisabled) this.thenFindFirst()
    }

    setBatchState = (state, callback) => {
      this.stateQueue.push({ value: state, callback: callback })
      this.thenExecStateQueue()
    }

    @debounced(200)
    thenExecStateQueue = () => {
      const batchedState = this.stateQueue.reduce((arr, current) => ({ ...arr.value, ...current.value }), {})
      this.setState(batchedState, () => {
        this.stateQueue.forEach(item => { if (item.callback) item.callback() })
        this.stateQueue = []
      })
    }

    setFocus = () => {
      const getNavEl = () => this.navigationEl?.current
      if (document.activeElement !== getNavEl()) {
        this.navigationEl.current?.focus()
      }
    }

    forceRefresh = options => {
      if (!this.isShouldStoreFocusable) this.resetParentY()
      this.state.selectedFocusable?.makeItSelected(false)
      this.setState({ selectedFocusable: null })
      // this.setBatchState({ selectedFocusable: null, subSelectedFocusable: null, storedFocusable: shouldStore ? this.state.selectedFocusable : null })
    }

    setDisabled = (disabled, shouldSavePosition) => {
      this.isDisabled = disabled
      this.setShouldStoreFocusableTo(disabled, shouldSavePosition)
      this.forceRefresh()
    }

    resetParentY = () => {
      const parent = this.state.selectedFocusable?.ref?.parentNode
      if (parent) parent.style.transform = 'translateY(0px)'
    }

    setShouldStoreFocusableTo = (should, shouldSavePosition) => {
      if ((should && !this.storedFocusable) || shouldSavePosition) {
        this.storedFocusable = this.state.selectedFocusable
      }
      this.isShouldStoreFocusable = shouldSavePosition || should
    }

    @debounced(options.findFirstDelay)
    thenFindFirst = (options = {}) => {
      const { areaId } = options
      if (this.isDisabled) return false
      if (options.withForce || areaId || (this.state.selectedFocusable === null && this.items.length > 0)) {

        const whatShouldBeNext = (!this.isShouldStoreFocusable && this.storedFocusable) || this.next
        if (whatShouldBeNext) {
          const next = whatShouldBeNext.key ? this.items.find(item => item[this.next.key] === this.next.value) : whatShouldBeNext
          const thereIs = next?.ref?.getBoundingClientRect().width !== 0
          if (next && thereIs) {
            this.setSelectedFocusable(next)
            this.storedFocusable = null
            this.isShouldStoreFocusable = false
            this.next = null
            return true
          }
        }

        const byFocusOrder = (a, b) => a.focusOrder - b.focusOrder
        const next = item => item.first
        const byAreaId = item => item._area_id === areaId

        const preparedItems = areaId ? this.items.filter(byAreaId) : this.items
        const first = preparedItems
          .sort(byFocusOrder)
          .find(next)

        const newSelected = first || preparedItems.first() || this.items.first()

        this.setSelectedFocusable(newSelected)
      }
    }

    addFocusable = focusable => {
      this.items.push(focusable)
      setTimeout(() => this.thenFindFirst(), 0)
    }

    getSelectedFocusable = () => {
      return this.state.selectedFocusable
    }

    removeFocusable = (_focus_id) => {
      const index = this.items.findIndex(item => item._focus_id === _focus_id)
      this.items.splice(index, 1)
      if (this.state.selectedFocusable?._focus_id === _focus_id) {
        this.setState({ selectedFocusable: null }, () => this.thenFindFirst())
      }
    }

    checkFocusable = _focus_id => this.items.findIndex(item => item._focus_id === _focus_id)

    findNearest = keyCode => {
      if (!this.state.selectedFocusable) return false
      try {
        const prevSelected = this.state.selectedFocusable
        const rect = getBoundingClientRect(prevSelected.ref)

        const selected = {
          ...prevSelected,
          rect: rect
        }
        const parent = selected.ref.parentNode

        const excludeSelf = item => item._focus_id !== selected._focus_id
        const recalculateRects = item => {
          const rect = getBoundingClientRect(item.ref)
          return {
            ...item,
            rect
          }
        }
        const byArea = item => selected.onlyArea ? item._area_id === selected._area_id : true
        const bySide = item => {
          const option = {
            debug: options.debug
          }
          const conditionBy = (keyCode, offset) => {
            if (options.keys.up.includes(keyCode)) return item => isInTriangle({ item: item.rect, from: selected.rect, where: 'up', offset: offset }, { debug: options.debug, expandTriangle: 250 })
            if (options.keys.right.includes(keyCode)) return item => isInTriangle({ item: item.rect, from: selected.rect, where: 'right' }, option)
            if (options.keys.down.includes(keyCode)) return item => isInTriangle({ item: item.rect, from: selected.rect, where: 'down' }, option)
            if (options.keys.left.includes(keyCode)) return item => isInTriangle({ item: item.rect, from: selected.rect, where: 'left' }, option)
          }
          const result = conditionBy(keyCode)(item)
          if (!result && options.keys.up.includes(keyCode)) return conditionBy(keyCode, getNodeTranslateY(parent))(item)
          return result
        }
        const preferList = (arr, item) => {
          const origin = arr.concat(item)
          const temp = origin.filter(i => i.isList)
          return temp?.length && selected.isList ? temp : origin
        }
        const withDistance = item => ({ ...item, distance: Math.hypot(item.rect.centerX - selected.rect.centerX, item.rect.centerY - selected.rect.centerY) })
        const byMinDistance = (prev, curr) => prev.distance < curr.distance ? prev : curr

        const whatWeSearchingFor = () => {
          if (selected.oneDimension && options.keys.right.includes(keyCode)) {
            const next = this.items.find(item => item._area_id === selected._area_id && item.focusOrder === selected.focusOrder + 1)
            if (next._focus_id) {
              return this.setSelectedFocusable(next)
            }
          }

          const nearest = this.items
            .filter(excludeSelf)
            .filter(byArea)
            .map(recalculateRects)
            .filter(bySide)
            .reduce(preferList, [])
            .map(withDistance)
            .reduce(byMinDistance, {})

          if (options.debug) drawItemCenter(nearest.rect.centerX, nearest.rect.centerY)

          if (nearest._area_id && (nearest._area_id !== selected._area_id)) {
            return this.thenFindFirst({ areaId: nearest._area_id, fast: true })
          }

          return this.setSelectedFocusable(nearest)
        }

        whatWeSearchingFor()

      } catch (e) {
        this.thenFindFirst()
        return false
      }
    }

    restoreStoredFocusable = () => {
      this.setSelectedFocusable(this.state.storedFocusable)
      this.setState({ storedFocusable: null, storedIgnored: false })
    }

    setSelectedFocusable = focusable => {
      if (!focusable?._focus_id) return false
      this.state.selectedFocusable?.makeItSelected(false)
      focusable.makeItSelected(true)

      this.setState({ selectedFocusable: focusable }, () => { this.checkVisibility(focusable) })
      this.nextFocusOrder = undefined
    }

    checkVisibility = focusable => {
      try {
        const rect = getBoundingClientRect(focusable.ref)
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight)

        const parent = focusable.ref.parentNode
        if (parent === null) { console.error('withNavigation.js', 'Null pointer to focusable.ref.parentNode'); this.forceRefresh(); return false }
        const grandParent = focusable.ref.parentNode.parentNode
        if (grandParent === null) { console.error('withNavigation.js', 'Null pointer to focusable.ref.grandParentNode'); this.forceRefresh(); return false }
        const grandParentRect = getBoundingClientRect(grandParent)

        const windowCenterY = Math.ceil(window.innerHeight / 2)
        const nearestCenterY = rect.top + rect.height / 2
        const distanceCenter = windowCenterY - nearestCenterY

        const distanceUpper = grandParentRect.top - rect.top
        const isUpper = distanceUpper > 0

        const distanceLower = windowHeight - rect.bottom
        const isLower = distanceLower < 0

        if (focusable.isList) {
          parent.style.transform = `translateY(calc(${getNodeTranslateY(parent) + distanceCenter}px))`
        } else
          if (isLower) {
            parent.style.transform = `translateY(calc(${getNodeTranslateY(parent) + distanceLower - 60}px))`
          } else
            if (isUpper) {
              parent.style.transform = `translateY(calc(${getNodeTranslateY(parent) + distanceUpper}px))`
            }
      } catch (e) {
        this.forceRefresh()
      }
    }

    @throttled(options.keyHandlingDelay)
    handleKeyPress = e => {
      const pressed = e.keyCode || e.key
      const back = options.keys.back
      const arrows = [...options.keys.up, ...options.keys.right, ...options.keys.down, ...options.keys.left]
      const enter = options.keys.enter

      if (back.includes(pressed)) { this.goBack(); this.playSound('click') }
      if (enter.includes(pressed)) {
        this.state.selectedFocusable?.onPress()
        this.playSound('click')
      }
      if (arrows.includes(pressed) && !this.isKeyCallbackUsed) { this.findNearest(pressed); this.playSound('move') }
      if (arrows.includes(pressed) && this.isKeyCallbackUsed && typeof this.keyCallback === 'function') { this.keyCallback(pressed); this.playSound('move') }
    }

    pressKey = key => {
      if (typeof key !== 'string') return false
      const event = new KeyboardEvent('keydown', { key: key })
      this.handleKeyPress(event)
    }

    setBackAction = fn => {
      if (!this.prevBackAction) this.prevBackAction = this.backAction
      this.backAction = fn || this.prevBackAction
      if (!fn) this.prevBackAction = null
    }

    goBack = () => {
      if (this.backAction) this.backAction()
    }

    playSound = type => {
      const sounds = {
        move: options.sounds.move,
        click: options.sounds.click
      }
      sounds[type]?.play()
    }

    render() {
      return (
        <div ref={this.navigationEl} tabIndex="0" onKeyDown={this.handleKeyPress} style={styles}>
          <WrappedComponent {...this.props} />
        </div>
      )
    }
  }

  WithNavigation.displayName = `WithNavigation(${getDisplayName(WrappedComponent)})`
  return WithNavigation
}

export default withNavigation

import { snakeCase } from 'change-case'
import jsbridge from 'utils/jsbridge'

const defaultMenus = [
  // {
  //   href: 'TV',
  //   title: 'LIVE TV',
  //   icon: 'tv',
  //   order: 0
  // },
  // {
  //   href: 'main',
  //   title: 'Main',
  //   icon: 'home',
  //   order: 1
  // },
  {
    href: 'messages',
    title: 'MESSAGES',
    icon: 'envelope',
    order: 1
  },
  {
    href: 'diary',
    title: 'diary',
    icon: 'calendar-alt',
    order: 2
  },
  {
    href: 'contacts',
    title: 'Contacts',
    icon: 'user-friends',
    order: 3
  },
  {
    href: 'interests',
    title: 'Interests',
    icon: 'thumbs-up',
    order: 4
  },
  // {
  //   href: 'services',
  //   title: 'Help',
  //   icon: 'question-circle',
  //   order: 5
  // },
  {
    title: 'Settings',
    icon: 'cogs',
    handlePress: () => jsbridge.call('openSettings'),
    order: 5
  }
]

export function getMenus() {
  return defaultMenus
}

export const newMock = [
  {
    'title': 'Main',
    'href': 'main',
    'color': 'rgba(80, 47, 95, 0.96)',
    'type': 'boxes',
    'items': [
      {
        'type': 'box',
        'title': 'Diary',
        'href': 'calendar',
        'color': 'rgba(80, 47, 95, 0.96)',
        'order': 0,
        'photo': '/assets/images/calendar.jpg'
      },
      {
        'type': 'content',
        'title': 'Recommended for you',
        'href': 'suggestions',
        'color': 'rgba(95,92,47,0.96)',
        'order': 1,
        'photo': '/assets/images/sugg-main-cube.png'
      },
      {
        'type': 'box',
        'title': 'Family & Friends',
        'color': 'rgba(70, 123, 38, 0.95)',
        'href': 'family',
        'order': 2,
        'photo': '/assets/images/family-main-cube.png'
      },
      {
        'type': 'box',
        'title': 'Indoor Activities',
        'color': 'rgba(201, 59, 19, 0.95)',
        'href': 'indoor_activities',
        'order': 3,
        'photo': '/assets/images/indoor-main-cube.png'
      },
      {
        'type': 'box',
        'title': 'Community & Outdoor Activities',
        'href': 'outdoor',
        'color': 'rgba(23, 91, 160, 0.9)',
        'order': 4,
        'photo': '/assets/images/outdoor-main-cube.png'
      },
      {
        'type': 'box',
        'title': 'MY AgeUK',
        'href': 'services',
        'color': 'rgba(20, 121, 108, 0.93)',
        'order': 5,
        'photo': '/assets/images/MY_AgeUK.png'
      }
    ]
  },
  {
    type: 'boxes',
    href: 'services',
    title: 'My AgeUK',
    'color': 'rgba(20, 121, 108, 0.93)',
    items: [
      {
        title: 's.a.i.l. (Safe and Independent Living)',
        href: 'services/s_a_i_l_safe_and_independent_living',
        photo: '/assets/images/SAIL.png'
      },
      {
        title: 'MYadvice',
        href: 'services/m_yadvice',
        photo: '/assets/images/MYadvice.png'
      },
      {
        title: 'Befriending',
        href: 'services/befriending',
        photo: '/assets/images/Befriending.png'
      },
      {
        title: 'HandyFix',
        href: 'services/handy_fix',
        photo: '/assets/images/handyFix.png'
      },
      {
        title: 'MYshopping',
        href: 'services/m_yshopping',
        photo: '/assets/images/MYshopping.png'
      },
    ]
  },
  {
    'type': 'content',
    'title': 'Recommended for you',
    'href': 'suggestions',
    'section': 'Indoor',
    'color': 'rgba(95,92,47,0.96)'
  },
  {
    'type': 'content',
    'title': 'Nurturing Relationships',
    'href': 'nurturing',
    'category': 'Family & Friends'
  },
  {
    'type': 'content',
    'title': 'Gifts',
    'href': 'gifts',
    'categoryName': 'Family & Friends'
  },
  {
    'type': 'boxes',
    'title': 'MY AgeUK',
    'href': 'services',
    'color': 'rgba(20, 121, 108, 0.93)'
  },
  {
    'title': 'Family & Friends',
    'href': 'family',
    'color': 'rgba(70, 123, 38, 0.95)',
    'type': 'boxes',
    'items': [
      {
        'type': 'box',
        'title': 'Call',
        'href': 'contacts',
        'order': 0,
        'photo': '/assets/images/family-contacts.png'
      },
      {
        'type': 'box',
        'title': 'Photos',
        'href': 'photos',
        'order': 1,
        'photo': '/assets/images/family-photos.png'
      },
      {
        'type': 'box',
        'title': 'Nurturing Relationships',
        'href': 'nurturing',
        'order': 2,
        'photo': '/assets/images/family-content.png'
      },
      {
        'type': 'box',
        'title': 'Gifts',
        'href': 'gifts',
        'order': 3,
        'photo': '/assets/images/family-gifts.png'
      }
    ]
  },
  {
    'title': 'Indoor Activities',
    'href': 'indoor_activities',
    'color': 'rgba(201, 59, 19, 0.95)',
    'type': 'boxes',
    hasSuggestions: true,
    hasSaved: true,
    'items': [
      {
        'type': 'box',
        'title': 'Lifelong Learning',
        'href': 'izidoou',
        'order': 0,
        'photo': '/assets/images/indoor-izidoou.png'
      },
      {
        'type': 'box',
        'title': 'Entertainment',
        'href': 'entertainment',
        'order': 1,
        'photo': '/assets/images/categories-entertainment.png'
      },
      {
        'type': 'box',
        'title': 'Live Better',
        'href': 'livebetter',
        'order': 2,
        'photo': '/assets/images/livebetter.png'
      },
      {
        'type': 'box',
        'title': 'Indoor Fitness',
        'href': 'indoor_fitness',
        'order': 3,
        'photo': '/assets/images/categories-indoor-fitness.png'
      }
    ]
  },
  {
    'title': 'Lifelong Learning',
    'href': 'izidoou',
    'color': 'rgba(201, 59, 19, 0.95)',
    'type': 'boxes',
    'items': [
      {
        'type': 'box',
        'title': 'Build Skills',
        'href': 'build_skills',
        'order': 0,
        'photo': '/assets/images/categories-aquire.png'
      },
      {
        'type': 'box',
        'title': 'Gain Knowledge',
        'href': 'gain_knowledge',
        'order': 1,
        'photo': '/assets/images/categories-enrichment.png'
      }
    ]
  },
  {
    'title': 'Build Skills',
    'href': 'build_skills',
    'color': 'rgba(201, 59, 19, 0.95)',
    'type': 'boxes',
    'items': [
      {
        'type': 'box',
        'title': 'Make Art',
        'href': 'make_art',
        'order': 0,
        'photo': '/assets/images/MakeArt.png'
      },
      {
        'type': 'box',
        'title': 'Learn to Cook',
        'href': 'learn_to_cook',
        'order': 1,
        'photo': '/assets/images/Cooking.png'
      },
      {
        'type': 'box',
        'title': 'Learn to Play an Instrument',
        'href': 'learn_to_play_an_instrument',
        'order': 2,
        'photo': '/assets/images/PlayanInstrument.png'
      },
      {
        'type': 'content',
        'title': 'Improve Your Game',
        'href': 'improve_your_game',
        'order': 3,
        'photo': '/assets/images/ImproveGame.png',
        'categoryName': 'Build Skills'
      },
      {
        'type': 'box',
        'title': 'Learn a Language',
        'href': 'learn_a_language',
        'order': 4,
        'photo': '/assets/images/LearnaLanguage.png',
        'categoryName': 'Build Skills'
      },
      {
        'type': 'content',
        'title': 'Technology',
        'href': 'technology',
        'order': 5,
        'photo': '/assets/images/Technology.png',
        'categoryName': 'Build Skills'
      },
      {
        'type': 'content',
        'title': 'Genealogy',
        'href': 'genealogy',
        'order': 5,
        'photo': '/assets/images/Genealogy.png',
        'categoryName': 'Build Skills'
      },
      {
        'type': 'content',
        'title': 'Learn to Play a Game',
        'href': 'learn_to_play_a_game',
        'order': 5,
        'photo': '/assets/images/LearntoPlay.png',
        'categoryName': 'Build Skills'
      },
      {
        'type': 'content',
        'title': 'Gardening',
        'href': 'gardening',
        'order': 5,
        'photo': '/assets/images/Gardening.png',
        'categoryName': 'Build Skills'
      }
    ]
  },
  {
    'type': 'box',
    'title': 'Make Art',
    'href': 'make_art',
    'color': 'rgba(201, 59, 19, 0.95)',
    'order': 0,
    'photo': '/assets/images/MakeArt.png',
    'items': [
      {
        title: 'Acrylic Painting',
        image: '/assets/images/AcrylicPainting.png'
      },
      {
        title: 'Watercolor Painting',
        image: '/assets/images/WatercolorPainting.png'
      },
      {
        title: 'Photography',
        image: '/assets/images/Photography.png'
      },
      {
        title: 'Arts & Crafts',
        image: '/assets/images/ArtsCrafts.png'
      }
    ]
  },
  {
    title: 'Acrylic Painting',
    image: '/assets/images/AcrylicPainting.png',
    category: 'Build Skills',
    categoryName: 'Make Art'
  },
  {
    title: 'Watercolor Painting',
    image: '/assets/images/WatercolorPainting.png',
    category: 'Build Skills',
    categoryName: 'Make Art'
  },
  {
    title: 'Photography',
    image: '/assets/images/Photography.png',
    category: 'Build Skills',
    categoryName: 'Make Art'
  },
  {
    title: 'Arts & Crafts',
    image: '/assets/images/ArtsCrafts.png',
    category: 'Build Skills',
    categoryName: 'Make Art'
  },
  {
    'type': 'boxes',
    'title': 'Learn to Play an Instrument',
    'href': 'learn_to_play_an_instrument',
    'order': 2,
    'photo': '/assets/images/PlayanInstrument.png',
    'items': [
      {
        title: 'Guitar',
        image: '/assets/images/Guitar.png'
      },
      {
        title: 'Piano',
        image: '/assets/images/Piano.png'
      },
      {
        title: 'Flute',
        image: '/assets/images/Flute.png'
      },
      {
        title: 'Harmonica',
        image: '/assets/images/Harmonica.png'
      },
      {
        title: 'Recorder',
        image: '/assets/images/Recorder.png'
      },
      {
        title: 'Trumpet',
        image: '/assets/images/Trumpet.png'
      }
    ]
  },
  {
    title: 'Guitar',
    image: '/assets/images/Guitar.png',
    category: 'Build Skills',
    categoryName: 'Learn to Play an Instrument'
  },
  {
    title: 'Piano',
    image: '/assets/images/Piano.png',
    category: 'Build Skills',
    categoryName: 'Learn to Play an Instrument'
  },
  {
    title: 'Flute',
    image: '/assets/images/Flute.png',
    category: 'Build Skills',
    categoryName: 'Learn to Play an Instrument'
  },
  {
    title: 'Harmonica',
    image: '/assets/images/Harmonica.png',
    category: 'Build Skills',
    categoryName: 'Learn to Play an Instrument'
  },
  {
    title: 'Recorder',
    image: '/assets/images/Recorder.png',
    category: 'Build Skills',
    categoryName: 'Learn to Play an Instrument'
  },
  {
    title: 'Trumpet',
    image: '/assets/images/Trumpet.png',
    category: 'Build Skills',
    categoryName: 'Learn to Play an Instrument'
  },
  {
    'type': 'content',
    'title': 'Improve Your Game',
    'href': 'improve_your_game',
    'order': 3,
    'photo': '/assets/images/ImproveGame.png',
    category: 'Build Skills'
  },
  {
    'type': 'boxes',
    'title': 'Learn a Language',
    'href': 'learn_a_language',
    'order': 4,
    'photo': '/assets/images/LearnaLanguage.png',
    'items': [
      {
        title: 'Spanish',
        image: '/assets/images/Spanish.png'
      },
      {
        title: 'German',
        image: '/assets/images/German.png'
      },
      {
        title: 'French',
        image: '/assets/images/French.png'
      }
    ]
  },
  {
    type: 'content',
    title: 'Spanish',
    href: 'spanish',
    image: '/assets/images/Spanish.png',
    category: 'Build Skills',
    categoryName: 'Learn a Language'
  },
  {
    type: 'content',
    title: 'German',
    href: 'german',
    image: '/assets/images/German.png',
    category: 'Build Skills',
    categoryName: 'Learn a Language'
  },
  {
    type: 'content',
    title: 'French',
    href: 'french',
    image: '/assets/images/French.png',
    category: 'Build Skills',
    categoryName: 'Learn a Language'
  },
  {
    'type': 'box',
    'title': 'Technology',
    'href': 'content',
    'order': 5,
    'photo': '/assets/images/Technology.png',
    'items': []
  },
  {
    'type': 'box',
    'title': 'Genealogy',
    'href': 'content',
    'order': 5,
    'photo': '/assets/images/Genealogy.png',
    'items': []
  },
  {
    'type': 'box',
    'title': 'Learn to Play a Game',
    'href': 'content',
    'order': 5,
    'photo': '/assets/images/LearntoPlay.png',
    'items': []
  },
  {
    'type': 'box',
    'title': 'Gardening',
    'href': 'content',
    'order': 5,
    'photo': '/assets/images/Gardening.png',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Learn to Cook',
    'category': 'Build Skills',
    'href': 'learn_to_cook',
    'order': 1,
    'photo': '/assets/images/Cooking.png'
  },
  {
    'title': 'Gain Knowledge',
    'href': 'gain_knowledge',
    'color': 'rgba(201, 59, 19, 0.95)',
    'type': 'boxes',
    'items': [
      {
        'type': 'content',
        'title': 'Lectures',
        'href': 'lectures',
        'order': 0,
        'photo': '/assets/images/Lectures.png',
        'categoryName': 'Gain Knowledge'
      },
      {
        'type': 'content',
        'title': 'Nature & Wildlife',
        'href': 'nature_wildlife',
        'order': 1,
        'photo': '/assets/images/NatureandWildlife.png',
        'categoryName': 'Gain Knowledge'
      },
      {
        'type': 'content',
        'title': 'Science',
        'href': 'science',
        'order': 2,
        'photo': '/assets/images/Science.png',
        'categoryName': 'Gain Knowledge'
      },
      {
        'type': 'content',
        'title': 'World Travel',
        'href': 'world_travel',
        'order': 3,
        'photo': '/assets/images/Travel.png',
        'categoryName': 'Gain Knowledge'
      },
      {
        'type': 'content',
        'title': 'Documentaries',
        'href': 'documentaries',
        'order': 4,
        'photo': '/assets/images/Documentaries.png',
        'categoryName': 'Gain Knowledge'
      },
      {
        'type': 'content',
        'title': 'History',
        'href': 'history',
        'order': 5,
        'photo': '/assets/images/History.png',
        'categoryName': 'Gain Knowledge'
      },
      {
        'type': 'content',
        'title': 'Art',
        'href': 'art',
        'order': 6,
        'photo': '/assets/images/MakeArt.png',
        'categoryName': 'Gain Knowledge'
      },
      {
        'type': 'content',
        'title': 'Music',
        'href': 'music',
        'order': 7,
        'photo': '/assets/images/PlayanInstrument.png',
        'categoryName': 'Gain Knowledge'
      }
    ]
  },
  {
    'title': 'Entertainment',
    'href': 'entertainment',
    'color': 'rgba(201, 59, 19, 0.95)',
    'type': 'boxes',
    hasSuggestions: true,
    hasSaved: true,
    'items': [
      {
        'type': 'content',
        'title': 'Music',
        'href': 'music',
        'order': 0,
        'photo': '/assets/images/Music.png',
        'categoryName': 'Entertainment'
      },
      {
        'type': 'content',
        'title': 'Classic Movies & TV',
        'href': 'classic_movies_tv',
        'order': 1,
        'photo': '/assets/images/Classic-Movies.png',
        'category': 'Entertainment'
      },
      {
        'type': 'content',
        'title': 'Comedy',
        'href': 'comedy',
        'order': 2,
        'photo': '/assets/images/ComedyCard.png',
        'categoryName': 'Entertainment'
      },
      {
        'type': 'content',
        'title': 'History',
        'href': 'history',
        'order': 3,
        'photo': '/assets/images/History.png',
        'categoryName': 'Entertainment'
      },
      {
        'type': 'content',
        'title': 'Travel',
        'href': 'travel',
        'order': 4,
        'photo': '/assets/images/Travel.png',
        'categoryName': 'Entertainment'
      },
      {
        'type': 'content',
        'title': 'Sport History',
        'href': 'sport_history',
        'order': 5,
        'photo': '/assets/images/Sports.png',
        'categoryName': 'Entertainment'
      },
      {
        'type': 'content',
        'title': 'Audio Books',
        'href': 'audio_books',
        'order': 6,
        'photo': '/assets/images/Audio-Books.png',
        'categoryName': 'Entertainment'
      },
      {
        'type': 'content',
        'title': 'Relaxation',
        'href': 'relaxation',
        'order': 7,
        'photo': '/assets/images/Relaxation.png',
        'categoryName': 'Entertainment'
      },
      {
        'type': 'content',
        'title': 'Audio Books',
        'href': 'audio_books',
        'order': 6,
        'photo': '/assets/images/Audio-Books.png',
        'categoryName': 'Entertainment'
      },
      {
        'type': 'content',
        'title': 'Relaxation',
        'href': 'relaxation',
        'order': 7,
        'photo': '/assets/images/Relaxation.png',
        'categoryName': 'Entertainment'
      }
    ]
  },
  {
    'title': 'Live Better',
    'href': 'livebetter',
    'color': 'rgba(201, 59, 19, 0.95)',
    'type': 'boxes',
    'items': [
      {
        'type': 'box',
        'title': 'Health & Aging',
        'href': 'health_and_aging',
        'order': 0,
        'photo': '/assets/images/categories-health.png'
      },
      {
        'type': 'box',
        'title': 'Safety',
        'href': 'safety',
        'order': 1,
        'photo': '/assets/images/categories-homesafety.png'
      }
    ]
  },
  {
    'title': 'Health & Aging',
    'href': 'health_and_aging',
    'color': 'rgba(201, 59, 19, 0.95)',
    'type': 'boxes',
    'items': [
      {
        'type': 'box',
        'title': 'Aging',
        'order': 0,
        'photo': '/assets/images/Aging.png'
      },
      {
        'type': 'box',
        'title': 'Chronic Health Conditions',
        'order': 1,
        'photo': '/assets/images/ChronicHealthConditions.png'
      },
      {
        'type': 'box',
        'title': 'Proper Nutrition',
        'order': 2,
        'photo': '/assets/images/ProperNutrition.png'
      },
      {
        'type': 'box',
        'title': 'Cognitive Health',
        'order': 3,
        'photo': '/assets/images/CognitiveHealth.png'
      },
      {
        'type': 'box',
        'title': 'Good Life Habits',
        'order': 4,
        'photo': '/assets/images/GoodLifeHabits.png'
      },
      {
        'type': 'box',
        'title': 'Sensory Impairments',
        'order': 5,
        'photo': '/assets/images/SensoryImpairments.png'
      }
    ]
  },
  {
    'type': 'content',
    'title': 'Aging',
    'order': 0,
    'photo': '/assets/images/Aging.png',
    category: 'Health & Aging'
  },
  {
    'type': 'content',
    'title': 'Chronic Health Conditions',
    'order': 1,
    'photo': '/assets/images/ChronicHealthConditions.png',
    category: 'Health & Aging'
  },
  {
    'type': 'content',
    'title': 'Proper Nutrition',
    'order': 2,
    'photo': '/assets/images/ProperNutrition.png',
    category: 'Health & Aging'
  },
  {
    'type': 'content',
    'title': 'Cognitive Health',
    'order': 3,
    'photo': '/assets/images/CognitiveHealth.png',
    category: 'Health & Aging'
  },
  {
    'type': 'content',
    'title': 'Good Life Habits',
    'order': 4,
    'photo': '/assets/images/GoodLifeHabits.png',
    category: 'Health & Aging'
  },
  {
    'type': 'content',
    'title': 'Sensory Impairments',
    'order': 5,
    'photo': '/assets/images/SensoryImpairments.png',
    category: 'Health & Aging'
  },
  {
    'title': 'Safety',
    'href': 'safety',
    'color': 'rgba(201, 59, 19, 0.95)',
    'type': 'boxes',
    'items': [
      {
        'type': 'content',
        'title': 'Safety Tips',
        'href': 'safety_tips',
        'order': 0,
        'photo': '/assets/images/SafetyTips.png',
        'categoryName': 'Safety'
      },
      {
        'type': 'content',
        'title': 'Fire Safety',
        'href': 'fire_safety',
        'order': 1,
        'photo': '/assets/images/FireSafety.png',
        'categoryName': 'Safety'
      },
      {
        'type': 'content',
        'title': 'Fall Prevention',
        'href': 'fall_prevention',
        'order': 2,
        'photo': '/assets/images/FallPrevention.png',
        'categoryName': 'Safety'
      },
      {
        'type': 'content',
        'title': 'Keeping Your House Secure',
        'href': 'keeping_your_house_secure',
        'order': 3,
        'photo': '/assets/images/KeepingYourHouseSecure.png',
        'categoryName': 'Safety'
      },
      {
        'type': 'content',
        'title': 'Winter Safety',
        'href': 'winter_safety',
        'order': 4,
        'photo': '/assets/images/WinterSafety.png',
        'categoryName': 'Safety'
      },
      {
        'type': 'content',
        'title': 'Bathroom Safety',
        'href': 'bathroom_safety',
        'order': 5,
        'photo': '/assets/images/BathroomSafety.png',
        'categoryName': 'Safety'
      },
      {
        'type': 'content',
        'title': 'Driving Safety',
        'href': 'driving_safety',
        'order': 6,
        'photo': '/assets/images/DrivingSafety.png',
        'categoryName': 'Safety'
      }
    ]
  },
  {
    'type': 'content',
    'title': 'Safety Tips',
    'href': 'safety_tips',
    'order': 0,
    'photo': '/assets/images/SafetyTips.png',
    category: 'Safety'
  },
  {
    'type': 'content',
    'title': 'Fire Safety',
    'href': 'fire_safety',
    'order': 1,
    'photo': '/assets/images/FireSafety.png',
    category: 'Safety'
  },
  {
    'type': 'content',
    'title': 'Fall Prevention',
    'href': 'fall_prevention',
    'order': 2,
    'photo': '/assets/images/FallPrevention.png',
    category: 'Safety'
  },
  {
    'type': 'content',
    'title': 'Keeping Your House Secure',
    'href': 'keeping_your_house_secure',
    'order': 3,
    'photo': '/assets/images/KeepingYourHouseSecure.png',
    category: 'Safety'
  },
  {
    'type': 'content',
    'title': 'Winter Safety',
    'href': 'winter_safety',
    'order': 4,
    'photo': '/assets/images/WinterSafety.png',
    category: 'Safety'
  },
  {
    'type': 'content',
    'title': 'Bathroom Safety',
    'href': 'bathroom_safety',
    'order': 5,
    'photo': '/assets/images/BathroomSafety.png',
    category: 'Safety'
  },
  {
    'type': 'content',
    'title': 'Driving Safety',
    'href': 'driving_safety',
    'order': 6,
    'photo': '/assets/images/DrivingSafety.png',
    category: 'Safety'
  },
  {
    'title': 'Indoor Fitness',
    'href': 'indoor_fitness',
    'color': 'rgba(201, 59, 19, 0.95)',
    'type': 'boxes',
    'items': [
      {
        'type': 'content',
        'title': 'Seated Exercises',
        'href': 'seated_exercises',
        'order': 0,
        'photo': '/assets/images/SeatedExercises.png',
        'categoryName': 'Indoor Fitness'
      },
      {
        'type': 'content',
        'title': 'Short Exercises',
        'href': 'short_exercises',
        'order': 1,
        'photo': '/assets/images/ShortExercises.png',
        'categoryName': 'Indoor Fitness'
      },
      {
        'type': 'content',
        'title': 'Standing Exercises',
        'href': 'standing_exercises',
        'order': 2,
        'photo': '/assets/images/StandingExercises.png',
        'categoryName': 'Indoor Fitness'
      },
      {
        'type': 'content',
        'title': 'Stretching Exercises',
        'href': 'stretching_exercises',
        'order': 3,
        'photo': '/assets/images/StretchingExercises.png',
        'categoryName': 'Indoor Fitness'
      },
      {
        'type': 'content',
        'title': 'Yoga',
        'href': 'yoga',
        'order': 4,
        'photo': '/assets/images/Yoga.png',
        'categoryName': 'Indoor Fitness'
      },
      {
        'type': 'content',
        'title': 'Meditation',
        'href': 'meditation',
        'order': 5,
        'photo': '/assets/images/Meditation.png',
        'categoryName': 'Indoor Fitness'
      },
      {
        'type': 'content',
        'title': 'Long Workouts',
        'href': 'long_workouts',
        'order': 6,
        'photo': '/assets/images/LongWorkouts.png',
        'categoryName': 'Indoor Fitness'
      }
    ]
  },
  {
    'type': 'content',
    'title': 'Seated Exercises',
    'href': 'seated_exercises',
    'order': 0,
    'photo': '/assets/images/SeatedExercises.png',
    'category': 'Indoor Fitness'
  },
  {
    'type': 'content',
    'title': 'Short Exercises',
    'href': 'short_exercises',
    'order': 1,
    'photo': '/assets/images/ShortExercises.png',
    'category': 'Indoor Fitness'
  },
  {
    'type': 'content',
    'title': 'Standing Exercises',
    'href': 'standing_exercises',
    'order': 2,
    'photo': '/assets/images/StandingExercises.png',
    'category': 'Indoor Fitness'
  },
  {
    'type': 'content',
    'title': 'Stretching Exercises',
    'href': 'stretching_exercises',
    'order': 3,
    'photo': '/assets/images/StretchingExercises.png',
    'category': 'Indoor Fitness'
  },
  {
    'type': 'content',
    'title': 'Yoga',
    'href': 'yoga',
    'order': 4,
    'photo': '/assets/images/Yoga.png',
    'category': 'Indoor Fitness'
  },
  {
    'type': 'content',
    'title': 'Meditation',
    'href': 'meditation',
    'order': 5,
    'photo': '/assets/images/Meditation.png',
    'category': 'Indoor Fitness'
  },
  {
    'type': 'content',
    'title': 'Long Workouts',
    'href': 'long_workouts',
    'order': 6,
    'photo': '/assets/images/LongWorkouts.png',
    'category': 'Indoor Fitness'
  },
  {
    'title': 'Community & Outdoor Activities',
    'href': 'outdoor',
    'color': 'rgba(23, 91, 160, 0.9)',
    'type': 'boxes',
    hasSuggestions: true,
    hasSaved: true,
    'items': [
      {
        'type': 'content',
        'title': 'Community Activities',
        'href': 'community_activities',
        'order': 0,
        'photo': '/assets/images/categories-events.png',
        'categoryName': 'Community'
      },
      {
        'type': 'content',
        'title': 'Community Fitness',
        'href': 'community_fitness',
        'order': 1,
        'photo': '/assets/images/categories-outdoorfitness.png',
        'categoryName': 'Community'
      }
    ]
  },
  {
    'type': 'content',
    'title': 'Improve Your Game',
    'href': 'improve_your_game',
    'order': 3,
    'photo': '/assets/images/ImproveGame.png',
    'category': 'Build Skills',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Technology',
    'href': 'technology',
    'order': 5,
    'photo': '/assets/images/Technology.png',
    'category': 'Build Skills',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Genealogy',
    'href': 'genealogy',
    'order': 5,
    'photo': '/assets/images/Genealogy.png',
    'category': 'Build Skills',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Learn to Play a Game',
    'href': 'learn_to_play_a_game',
    'order': 5,
    'photo': '/assets/images/LearntoPlay.png',
    'category': 'Build Skills',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Gardening',
    'href': 'gardening',
    'order': 5,
    'photo': '/assets/images/Gardening.png',
    'category': 'Build Skills',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Acrylic Painting',
    'href': 'acrylic_painting',
    'order': 0,
    'photo': '/assets/images/AcrylicPainting.png',
    'category': 'Make Art',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Watercolor Painting',
    'href': 'watercolor_painting',
    'order': 1,
    'photo': '/assets/images/WatercolorPainting.png',
    'category': 'Make Art',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Photography',
    'href': 'photography',
    'order': 2,
    'photo': '/assets/images/Photography.png',
    'category': 'Make Art',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Arts & Crafts',
    'href': 'arts_crafts',
    'order': 3,
    'photo': '/assets/images/ArtsCrafts.png',
    'category': 'Make Art',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Lectures',
    'href': 'lectures',
    'order': 0,
    'photo': '/assets/images/Lectures.png',
    'category': 'Gain Knowledge',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Nature & Wildlife',
    'href': 'nature_wildlife',
    'order': 1,
    'photo': '/assets/images/NatureandWildlife.png',
    'category': 'Gain Knowledge',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Science',
    'href': 'science',
    'order': 2,
    'photo': '/assets/images/Science.png',
    'category': 'Gain Knowledge',
    'items': []
  },
  {
    'type': 'content',
    'title': 'World Travel',
    'href': 'world_travel',
    'order': 3,
    'photo': '/assets/images/Travel.png',
    'category': 'Gain Knowledge',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Documentaries',
    'href': 'documentaries',
    'order': 4,
    'photo': '/assets/images/Documentaries.png',
    'category': 'Gain Knowledge',
    'items': []
  },
  {
    'type': 'content',
    'title': 'History',
    'href': 'history',
    'order': 5,
    'photo': '/assets/images/History.png',
    'category': 'Gain Knowledge',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Art',
    'href': 'art',
    'order': 6,
    'photo': '/assets/images/MakeArt.png',
    'category': 'Gain Knowledge',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Music',
    'href': 'music',
    'order': 7,
    'photo': '/assets/images/PlayanInstrument.png',
    'category': 'Gain Knowledge',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Music',
    'href': 'music',
    'order': 0,
    'photo': '/assets/images/Music.png',
    'category': 'Entertainment',
    'items': []
  },
  {
    'type': 'boxes',
    'title': 'Classic Movies & TV',
    'href': 'classic_movies_tv',
    'order': 1,
    'photo': '/assets/images/Classic-Movies.png',
    'category': 'Entertainment',
    'items': [
      {
        title: 'Monty Python\'s Flying Circus',
        image: '/assets/images/MontyPython.jpg'
      },
      {
        title: 'Doctor Who',
        image: '/assets/images/DoctorWho.jpg'
      },
      {
        title: 'The Morecambe & Wise Show',
        image: '/assets/images/TheMorecambeWiseShow.jpg'
      },
      {
        title: 'Alfred Hitchcock Presents',
        image: '/assets/images/AlfredHitchcockPresents.png',
        cardOnly: true
      },
      {
        title: 'Hancock\'s Half Hour',
        image: '/assets/images/HancocksHalfHour.jpg'
      },
      {
        title: 'Coronation Street',
        image: '/assets/images/CoronationStreet.jpg'
      },
      {
        title: 'The Avengers',
        image: '/assets/images/TheAvengers.jpg'
      },
      {
        title: 'British TV',
        image: '/assets/images/BritishTV.jpg',
        categoryOnly: true
      },
      {
        title: 'Classic Movies',
        image: '/assets/images/Classic-Movies.png',
        categoryOnly: true
      },
      {
        title: 'The Forsyte Sage',
        image: '/assets/images/TheForsyteSage.jpg',
        cardOnly: true
      },
      {
        title: 'World In Action',
        image: '/assets/images/WorldInAction.jpg',
        cardOnly: true
      },
      {
        title: 'Ready Steady Go',
        image: '/assets/images/ReadySteadyGo.gif',
        cardOnly: true
      },
      {
        title: 'Z-Cars',
        image: '/assets/images/ZCars.jpg',
        cardOnly: true
      },
      {
        title: 'The Likely Lads',
        image: '/assets/images/TheLikelyLads.jpg',
        cardOnly: true
      },
      {
        title: 'That Was The Week That Was',
        image: '/assets/images/ThatWasTheWeekThatWas.jpg',
        cardOnly: true
      },
      {
        title: 'University Challenge',
        image: '/assets/images/UniversityChallenge.jpg',
        cardOnly: true
      }, {
        title: 'Porridge',
        image: '/assets/images/Porridge.jpg',
        cardOnly: true
      },
      {
        title: 'Top Of The Pops',
        image: '/assets/images/TopOfThePops.jpg',
        cardOnly: true
      },
      {
        title: 'Steptoe and Son',
        image: '/assets/images/SteptoeAndSon.jpg',
        cardOnly: true
      },
      {
        title: 'Quatermass and the Pit',
        image: '/assets/images/QuatermassAndThePit.JPG',
        cardOnly: true
      }
    ]
  },
  {
    title: 'Monty Python\'s Flying Circus',
    image: '/assets/images/MonthyPython.jpg',
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'Doctor Who',
    image: '/assets/images/DoctorWho.jpg',
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'The Morecambe & Wise Show',
    image: '/assets/images/TheMorecambeWiseShow.jpg',
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'Alfred Hitchcock Presents',
    image: '/assets/images/AlfredHitchcockPresents.png',
    cardOnly: true,
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'Hancock\'s Half Hour',
    image: '/assets/images/HancocksHalfHour.jpg',
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'Coronation Street',
    image: '/assets/images/CoronationStreet.jpg',
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'The Avengers',
    image: '/assets/images/TheAvengers.jpg',
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'British TV',
    image: '/assets/images/BritishTV.jpg',
    categoryOnly: true,
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'Classic Movies',
    image: '/assets/images/Classic-Movies.png',
    categoryOnly: true,
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'The Forsyte Sage',
    image: '/assets/images/TheForsyteSage.jpg',
    cardOnly: true,
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'World In Action',
    image: '/assets/images/WorldInAction.jpg',
    cardOnly: true,
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'Ready Steady Go',
    image: '/assets/images/ReadySteadyGo.gif',
    cardOnly: true,
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'Z-Cars',
    image: '/assets/images/ZCars.jpg',
    cardOnly: true,
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'The Likely Lads',
    image: '/assets/images/TheLikelyLads.jpg',
    cardOnly: true,
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'That Was The Week That Was',
    image: '/assets/images/ThatWasTheWeekThatWas.jpg',
    cardOnly: true,
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'University Challenge',
    image: '/assets/images/UniversityChallenge.jpg',
    cardOnly: true,
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  }, {
    title: 'Porridge',
    image: '/assets/images/Porridge.jpg',
    cardOnly: true,
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'Top Of The Pops',
    image: '/assets/images/TopOfThePops.jpg',
    cardOnly: true,
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'Steptoe and Son',
    image: '/assets/images/SteptoeAndSon.jpg',
    cardOnly: true,
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    title: 'Quatermass and the Pit',
    image: '/assets/images/QuatermassAndThePit.JPG',
    cardOnly: true,
    type: 'content',
    category: 'Entertainment',
    categoryName: 'Classic Movies & TV'
  },
  {
    'type': 'content',
    'title': 'Comedy',
    'href': 'comedy',
    'order': 2,
    'photo': '/assets/images/ComedyCard.png',
    'categoryName': 'Entertainment',
    'items': []
  },
  {
    'type': 'content',
    'title': 'History',
    'href': 'history',
    'order': 3,
    'photo': '/assets/images/History.png',
    'categoryName': 'Entertainment',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Travel',
    'href': 'travel',
    'order': 4,
    'photo': '/assets/images/Travel.png',
    'categoryName': 'Entertainment',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Sport History',
    'href': 'sport_history',
    'order': 5,
    'photo': '/assets/images/Sports.png',
    'categoryName': 'Entertainment',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Audio Books',
    'href': 'audio_books',
    'order': 6,
    'photo': '/assets/images/Audio-Books.png',
    'categoryName': 'Entertainment',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Relaxation',
    'href': 'relaxation',
    'order': 7,
    'photo': '/assets/images/Relaxation.png',
    'categoryName': 'Entertainment',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Safety Tips',
    'href': 'safety_tips',
    'order': 0,
    'photo': '/assets/images//SafetyTips.png',
    'categoryName': 'Safety',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Fire Safety',
    'href': 'fire_safety',
    'order': 1,
    'photo': '/assets/images//FireSafety.png',
    'categoryName': 'Safety',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Fall Prevention',
    'href': 'fall_prevention',
    'order': 2,
    'photo': '/assets/images//FallPrevention.png',
    'categoryName': 'Safety',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Keeping Your House Secure',
    'href': 'keeping_your_house_secure',
    'order': 3,
    'photo': '/assets/images//KeepingYourHouseSecure.png',
    'categoryName': 'Safety',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Winter Safety',
    'href': 'winter_safety',
    'order': 4,
    'photo': '/assets/images//WinterSafety.png',
    'categoryName': 'Safety',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Bathroom Safety',
    'href': 'bathroom_safety',
    'order': 5,
    'photo': '/assets/images//BathroomSafety.png',
    'categoryName': 'Safety',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Driving Safety',
    'href': 'driving_safety',
    'order': 6,
    'photo': '/assets/images//DrivingSafety.png',
    'categoryName': 'Safety',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Seated Exercises',
    'href': 'seated_exercises',
    'order': 0,
    'photo': '/assets/images/SeatedExercises.png',
    'categoryName': 'Indoor Fitness',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Short Exercises',
    'href': 'short_exercises',
    'order': 1,
    'photo': '/assets/images/ShortExercises.png',
    'categoryName': 'Indoor Fitness',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Standing Exercises',
    'href': 'standing_exercises',
    'order': 2,
    'photo': '/assets/images/StandingExercises.png',
    'categoryName': 'Indoor Fitness',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Stretching Exercises',
    'href': 'stretching_exercises',
    'order': 3,
    'photo': '/assets/images/StretchingExercises.png',
    'categoryName': 'Indoor Fitness',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Yoga',
    'href': 'yoga',
    'order': 4,
    'photo': '/assets/images/Yoga.png',
    'categoryName': 'Indoor Fitness',
    'items': []
  },
  {
    'type': 'content',
    'title': 'Meditation',
    'href': 'meditation',
    'order': 5,
    'photo': '/assets/images/Meditation.png',
    'categoryName': 'Indoor Fitness',
  },
  {
    'type': 'content',
    'title': 'Long Workouts',
    'href': 'long_workouts',
    'order': 6,
    'photo': '/assets/images/LongWorkouts.png',
    'categoryName': 'Indoor Fitness',
  },
  {
    'type': 'events',
    'title': 'Community Activities',
    'href': 'community_activities',
    'photo': '/assets/images/categories-events.png',
    category: 'Community Activities'
  },
  {
    'type': 'events',
    'title': 'Community Fitness',
    'href': 'community_fitness',
    'order': 1,
    'photo': '/assets/images/categories-outdoorfitness.png',
    category: 'Community Fitness'
  },
  {
    'type': 'content',
    'title': 'Saved Activities',
    'href': 'saved_activities',
    'order': 3,
    'photo': '/assets/images/saved.png',
  },
  {
    'type': 'content',
    'title': 'Monty Python\'s Flying Circus',
    'href': 'monty_python_s_flying_circus',
    'order': 3,
    'photo': '/assets/images/saved.png',
    'category': 'Entertainment',
    'categoryName': 'Classic Movies & TV'
  },
  {
    'type': 'content',
    'title': 'Doctor Who',
    'href': 'doctor_who',
    'order': 0,
    'photo': '/assets/images/DoctorWho.jpg',
    'category': 'Entertainment',
    categoryName: 'Classic Movies & TV'
  }
]

const timeout = process.env.NODE_ENV === 'development' ? 500 : 0

export async function getMockData(page) {
  return new Promise(resolve => {
    setTimeout(() => {

      // const newmock = mockData.map(item => {
      //   const newItems = item.items?.map(subItem => {
      //     if (subItem.href === 'content' || subItem.href === 'categories' ) {
      //       const page = {
      //         ...subItem,
      //         type: subItem.href === 'content' ? 'content' : 'box',
      //         href: snakeCase(subItem.title.toLowerCase()),
      //         category: item.title
      //       }
      //       return page
      //     }
      //     return subItem
      //   }) || []
      //   console.log('---111', newItems)
      //
      //   return {
      //     ...item,
      //     items: newItems
      //   }
      // })
      //
      // console.log('---flat', newmock)
      const byHrefOrTitle = () => {
        return newMock.find(item => item.href === page) || newMock.find(item => snakeCase(item.title) === page)
      }
      resolve(byHrefOrTitle())
    }, timeout)
  })
}


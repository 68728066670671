import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { theme } from 'UI'

const StyledIcon = styled(FontAwesomeIcon)`
  ${props => props.color && `color: ${props.color}`}
`

const OkWrap = styled.div`
  position: relative;
`
const Ok = styled.div`
  ${theme.css.centered};
  font-size: 0.4em;
  filter: invert(100%);
`


const Icon = ({ focus, color, iconColor, icon, ...props }) => icon === 'ok'
  ? <OkWrap {...props}><StyledIcon color={color||iconColor} icon='square' {...props} /><Ok>OK</Ok></OkWrap>
  : <StyledIcon icon={icon} color={color||iconColor} {...props} />

export default Icon

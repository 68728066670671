import React, { Component } from 'react'
import { Switch, Route } from 'react-router'

import { ServicesList, ServicesItem } from 'components/Services'
import { inject, observer } from 'mobx-react'

@inject('servicesStore')
@observer
class Services extends Component {

  componentDidMount() {
    const { servicesStore } = this.props
    servicesStore.getHandled()
    servicesStore.getServices()
  }

  render() {
    return (
      <Switch>
        <Route exact path='/main/services' component={ServicesList} />
        <Route path='/main/services/:serviceId' component={ServicesItem} />
      </Switch>
    )
  }
}

export default Services

import { observable, action, computed, toJS } from 'mobx'
import { format, addDays, parseISO, isWithinInterval, isBefore, isAfter, isSameDay, getMonth, addMonths, addWeeks, isToday, isTomorrow, addYears, } from 'date-fns'
import { enGB } from 'date-fns/locale'
import { fromPromise } from 'mobx-utils'

import { api } from 'utils/fetcher'
import {  deleteFrom, withStatusFor } from 'utils/storeHelpers'

const formatDate = date => format(date, 'yyyy-MM-dd')
const isThisWeek = (date) => isBefore(date, addDays(new Date(), 7))

const today = formatDate(new Date())
const tomorrow = formatDate(addDays(new Date(), 1))
const month = formatDate(addMonths(new Date(), 1))
const week = formatDate(addWeeks(new Date(), 1))
class EventsStore {
  @observable _events = fromPromise(api.get().from.events.fromTo(today, month))
  @observable _storedEvents = []
  @computed get link() { return this._events?.value?.items }
  @computed get events() { return (this.isLoading ? toJS(this._storedEvents) : toJS(this._events?.value?.items)) || [] }

  @action
  getEvents = (fromDate, toDate) => {
    this._storedEvents = this.events
    const from = fromDate ? format(new Date(fromDate), 'yyyy-MM-dd') : today
    const to = toDate ? format(new Date(toDate), 'yyyy-MM-dd') : month
    this._events = fromPromise(api.get().from.events.fromTo(from, to))
  }

  deleteEvent = async eventId =>{
    deleteFrom(this.link).where('id', eventId)
    const res = await fromPromise(api.delete().to.events.delete(eventId))
    if (res.status === 200) {
      this.getEvents(today, month)
    }
  }

  today() {
    return this.events.filter(event => isSameDay(parseISO(event.from.official[0]), new Date())) || []
  }

  todayNotHappenedYet(){
    return this.events.filter(event => isSameDay(parseISO(event.from.official[0]), new Date()) && isBefore(new Date(),parseISO(event.from.official[0]))) || []
  }
  @computed get week() {
    const futureWeek = this.events
      .map(event => ({ ...event, ISOdate: parseISO(event.from.official[0]), day: parseISO(event.from.official[0]).getDate() }))
      .reduce((obj, curr) => ({ ...obj, [curr.day]: obj[curr.day] ? [ ...obj[curr.day], curr ] : [ curr ]}), {})
    return Object.entries(futureWeek)
  }

  @computed get weekDiveded() {
    const futureWeek = this.events
      .map(event => ({ ...event, ISOdate: parseISO(event.from.official[0]), day: parseISO(event.from.official[0]).getDate() }))
      .reduce((obj, curr) => 
      isToday(curr.ISOdate) || isTomorrow(curr.ISOdate) ?
      ({ ...obj, [curr.day]: obj[curr.day] ? [ ...obj[curr.day], curr ] : [ curr ]}) :
      isThisWeek(curr.ISOdate) ? ({ ...obj, ["week"]: obj["week"]? [ ...obj["week"], curr ] : [ curr ]}) :
      ({ ...obj, ["others"]: obj["others"]? [ ...obj["others"], curr ] : [ curr ]})
      , {}
      )
    return Object.entries(futureWeek)
  }
  @computed get month() {
    const month = [...Array(28).keys()]
      .map((_, index) => {
        const date = addDays(new Date(), index, { locale: enGB })
        const day = {
          date: date,
          month: format(date, 'LLLL'),
          events: this.events.filter( event => isSameDay(parseISO(event.from.official[0]), date))
        }
        return day
      })
      .reduce((obj,curr) => ({...obj, [curr.month]: obj[curr.month] ? [ ...obj[curr.month], curr ]: [ curr ]}),{})
    return Object.entries(month)
  }

  refreshStore = () => this.getEvents(today, month)

}

export const eventsStore = new EventsStore()
export default withStatusFor(eventsStore, { withMainField: '_events', onPath: '/main/diary', emptyMessage: 'No events to show' })
